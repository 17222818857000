<div class="wrapper">


    <div *ngIf="selectedCategory && selectedCategory.filters.length" class="filters-mobile mobile">
        <div (click)="showFilters=!showFilters" class="mobile">
            <span class="filter-tab">{{langS.translate('filterT')}}</span>
            <hr>
        </div>
        <div class="filter-box" *ngIf="showFilters">
            <div *ngFor="let filter of selectedCategory.filters" class="filter-group">
                <span class="filter-title">{{ filter.name[langS.activeLang] }}</span>
                <div class="filters-list">
                    <div *ngFor="let value of filter.values" class="filter" (click)="addFilter(filter, value)">
                        <span class="name">{{ value.data }}</span>
                        <span class="count">({{ value.quantity }})</span>
                        <input type="checkbox" [checked]="filterIsApplied(filter, value)" />
                    </div>
                </div>
            </div>
        </div>
        <button class="btn" *ngIf="showFilters" (click)="updateProductsList()">apply</button>
    </div>
    <div class="content">
        <div *ngIf="selectedCategory && selectedCategory.filters.length" class="filters">
            <div *ngFor="let filter of selectedCategory.filters" class="filter-group">
                <span class="filter-title">{{ filter.name[langS.activeLang] }}</span>
                <div class="filters-list">
                    <div *ngFor="let value of filter.values" class="filter" (click)="addFilter(filter, value)">
                        <span class="name">{{ value.data }}</span>
                        <span class="count">({{ value.quantity }})</span>
                        <input type="checkbox" [checked]="filterIsApplied(filter, value)" />
                    </div>
                </div>
            </div>
            <button class="btn" (click)="updateProductsList()">apply</button>
        </div>

        <div class="products">
            <div class="products-header">
                <div class="left-group">
                    <div class="item">
                        Show by: 15 <i class="fa-solid fa-angle-down"></i>
                    </div>
                    <div class="item">
                        Soft by: Best selling <i class="fa-solid fa-angle-down"></i>
                    </div>
                </div>
                <div class="right-group">
                    <div class="item">Items 1 - 15 of 28 total</div>
                </div>
            </div>

            <div class="content">
                <div class="products-list">
                    <div *ngFor="let product of products" [routerLink]="['/product', product.id]" class="product">
                        <figure class="image">
                            <img [src]="_settings.getImgSrc(product.mainImage)"
                                [alt]="product.mainImage.alt[langS.activeLang]" />
                            <!-- <div class="add-to-cart" (click)="addToCart($event,product)">
                                {{langS.translate('addBasket')}}
                            </div> -->
                        </figure>

                        <span class="title">{{ product.title[langS.activeLang] }}</span>
                        <span class="price" [class.is-sale]="product.sale > 0">
                            <div class="price-tag">
                                <span class="actual-price">
                                    {{ _settings.getProductPriceTag(true,product.variants[0],product.sale) }} MDL
                                </span> <br>
                                <span *ngIf="product.sale > 0" class="old-price">
                                    ({{ _settings.getProductPriceTag(false,product.variants[0],product.sale) }} MDL)
                                </span>
                            </div>
                            <span (click)="addToCart($event,product)" [title]="langS.translate('addBasket')"
                                class="cart fa-solid fa-cart-shopping"></span>
                        </span>
                    </div>
                </div>
            </div>

            <app-pagination-2 [selectedPage]="selectedPage" (selectedPageChange)="changePage($event)"
                *ngIf="pagesQuantity > 1" [pagesQuantity]="pagesQuantity">
            </app-pagination-2>
        </div>
    </div>
    <!--
  <div class="like-products">
    <div class="zigzag-block">
      <div class="zigzag"></div>
      <div class="zigzag"></div>
    </div>

    <div class="info">
      <h2 class="title">Like products</h2>
      <p class="description">See all</p>
    </div>

    <div class="products-list">
      <div *ngFor="let product of [1, 2, 3]" class="product">
        <figure class="image">
          <img src="../../../assets/product/product_1.jpg" alt="" />
        </figure>

        <span class="title">JWDA Concrete Lamp</span>
        <span class="price">340 MDL</span>
      </div>
    </div>
  </div> -->
</div>