import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SsrService } from '../_services/ssr.service';
import { getCookie } from '../_utils/cookieUtils';
import { SettingsService } from '../_services/settings.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
    constructor(
        private ssrS: SsrService,
        private router: Router,
    ) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let headers: any = {};
        let url = req.url;
        if (req.url.startsWith('/')) {
            if (
                !req.headers.get('Content-Type') &&
                ((this.ssrS.isBrowser && !(req.body instanceof FormData)) ||
                    !this.ssrS.isBrowser)
            ) {
                headers['Content-Type'] = 'application/json'; // FormData - передаём файлы
            }
            if (this.ssrS.isBrowser) {
                headers['Authorization'] = this.ssrS.isBrowser ? (localStorage.getItem('token') || getCookie("token")) + '' : '';
            }
            headers['Language'] = 'ru';
            url = environment.url + req.url;
        }
        return next.handle(req.clone({ setHeaders: headers, url })).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status == 404) this.router.navigate(['/auth/page-404']);

                return throwError(error);
            })
        );
    }
}
