import { SliderComponent } from './slider/slider.component';
import { ValidationErrorShowComponent } from './validation-error-show/validation-error-show.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Pagination2Component } from './pagination2/pagination2.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ValidationErrorShowComponent,
    Pagination2Component,
    SliderComponent,
  ],
  imports: [CommonModule, RouterModule, ReactiveFormsModule, FormsModule],
  exports: [
    Pagination2Component,
    ValidationErrorShowComponent,
    SliderComponent,
  ],
})
export class SharedModule {}
