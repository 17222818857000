<div *ngIf="visible" class="ys-modal">
    <div (click)="hideModal($event)" class="modal-bg"></div>

    <div class="modal-wrapper" [class.loader-wrapper]="isLoader">
        <div *ngIf="!isLoader" class="modal-header">
            <span class="modal-title">{{ headerTitle }}</span>
            <i (click)="hideModal()" class="pi pi-times icon-close"></i>
        </div>
        <div class="modal-content">
            <div *ngIf="isLoader" class="loader">
                <!-- <ion-spinner></ion-spinner> -->
                <span class="label">Please wait ...</span>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
</div>