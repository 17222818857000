<div class="header-bar">
    <div class="box">
        <div class="info-item" *ngIf="settings.contacts">
            <div>
                <i class="pi pi-phone"></i>
                <span class="value">{{ settings.contacts[0].phone }}</span>
            </div>
            <div>
                <i class="fa fa-shipping-fast"></i>
                <span class="value">LIVRARE ÎN TOATĂ MOLDOVA</span>
            </div>
        </div>
    </div>
</div>
<div id="wrapper" class="wrapper">
    <div class="menu-wrapper">
        <div class="top-menu">
            <div class="left-block">
                <button class="menu-btn lang-btn">
                    {{ langS.activeLang }}
                    <div class="select-lang">
                        <div *ngFor="let lang of langS.languages" (click)="langS.selectLang(lang)"
                            class="menu-btn lang">
                            {{ lang }}
                        </div>
                    </div>
                </button>
                <button [routerLink]="['/favorites']" class="menu-btn">
                    <i class="fa-solid fa-heart"></i>
                    <div class="count">{{ favoriteS.productsIds.length }}</div>
                </button>
                <!-- <button class="menu-btn">
          <i class="fa-solid fa-dollar-sign"></i>
        </button> -->
                <!-- <button class="menu-btn">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button> -->
            </div>

            <div class="search-wrapper">
                <div class="search-block">
                    <input (input)="searchProductSubs.emit($event)" [placeholder]="langS.translate('search')"
                        type="text" />
                    <i class="fa-solid fa-magnifying-glass"></i>
                </div>

                <div *ngIf="searchedProducts" class="search-results-wrapper">
                    <div class="list">
                        <div *ngFor="let product of searchedProducts" (click)="selectSearchProduct(product)"
                            class="product">
                            <figure class="cover">
                                <img [src]="settings.getImgSrc(product.mainImage)" alt="" />
                            </figure>
                            <div class="info">
                                <span class="title">{{ product.title[langS.activeLang] }}</span>
                                <span class="price">{{ product.price }} MDL</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="searchedProducts && searchedProducts.length == 0" class="not-found">
                        <h2>{{ langS.translate("notFound") }}</h2>
                    </div>
                </div>
            </div>

            <div class="right-block">
                <button *ngIf="!auth.user" (click)="settings.isShowLogin = true" class="menu-btn">
                    {{ langS.translate("login") }}
                </button>
                <button *ngIf="auth.user" [routerLink]="['/user']" class="menu-btn">
                    <i class="fa-solid fa-user"></i>
                </button>

                <button routerLink="/cart" class="menu-btn">
                    <i class="fa-solid fa-cart-shopping"></i>
                    <div class="count cart-count">
                        {{ cartS.cart ? cartS.cart.length : 0 }}
                    </div>
                </button>
            </div>
        </div>

        <div class="bottom-menu">
            <figure [routerLink]="['/']" class="logo">
                <img *ngIf="settings.logo" [src]="settings.getImgSrc(settings.logo)" alt="" />
                <span class="logo-text">mobiladevis.md</span>
            </figure>

            <div class="nav">
                <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="item">{{
                    langS.translate("home") }}</a>
                <div class="item" (click)="toggleCategories($event)">
                    <a>{{ langS.translate("categories") }}</a>
                    <div class="toggle">
                        <i [class]="
                isShowCategories
                  ? 'fa-solid fa-angle-up'
                  : 'fa-solid fa-angle-down'
              "></i>
                    </div>
                </div>
                <a routerLink="/page/about-us" routerLinkActive="active" class="item">{{
                    langS.translate("aboutUs")
                    }}</a>
                <a routerLink="/contacts" routerLinkActive="active" class="item">{{
                    langS.translate("contacts")
                    }}</a>
            </div>

            <div *ngIf="settings.contacts" class="social">
                <a *ngIf="settings.contacts[0].socials.facebook" [href]="settings.contacts[0].socials.facebook"
                    target="_blank" class="item">
                    <i class="fa-brands fa-facebook-f"></i>
                </a>
                <a *ngIf="settings.contacts[0].socials.instagram" [href]="settings.contacts[0].socials.instagram"
                    target="_blank" class="item">
                    <i class="fa-brands fa-instagram"></i>
                </a>
                <a *ngIf="settings.contacts[0].socials.playmarket" [href]="settings.contacts[0].socials.playmarket"
                    target="_blank" class="item">
                    <i class="fa-brands fa-google-play"></i>
                </a>
                <a *ngIf="settings.contacts[0].socials.viber" [href]="settings.contacts[0].socials.viber"
                    target="_blank" class="item">
                    <i class="fa-brands fa-viber"></i>
                </a>
                <a *ngIf="settings.contacts[0].socials.telegram" [href]="settings.contacts[0].socials.telegram"
                    target="_blank" class="item">
                    <i class="fa-brands fa-telegram"></i>
                </a>
                <a *ngIf="settings.contacts[0].socials.twitter" [href]="settings.contacts[0].socials.twitter"
                    target="_blank" class="item">
                    <i class="fa-brands fa-twitter"></i>
                </a>
                <a *ngIf="settings.contacts[0].socials.youtube" [href]="settings.contacts[0].socials.youtube"
                    target="_blank" class="item">
                    <i class="fa-brands fa-youtube"></i>
                </a>
                <a *ngIf="settings.contacts[0].socials.pinterest" [href]="settings.contacts[0].socials.pinterest"
                    target="_blank" class="item">
                    <i class="fa-brands fa-pinterest-p"></i>
                </a>
                <a *ngIf="settings.contacts[0].socials.vk" [href]="settings.contacts[0].socials.vk" target="_blank"
                    class="item">
                    <i class="fa-brands fa-vk"></i>
                </a>
                <a *ngIf="settings.contacts[0].socials.ok" [href]="settings.contacts[0].socials.ok" target="_blank"
                    class="item">
                    <i class="fa-brands fa-odnoklassniki"></i>
                </a>
            </div>
        </div>

        <div *ngIf="isShowCategories && categoryService.rootCategories.length" [class.view-categories]="
        isShowCategories && categoryService.rootCategories.length
      " class="categories">
            <div *ngFor="let category of categoryService.rootCategories; let ind = index" class="item"
                routerLinkActive="active">
                <figure class="cover">
                    <img [src]="settings.getImgSrc(category.icon)" [alt]="category.icon.alt[langS.activeLang]" />
                </figure>
                <span class="name">{{ category.title[this.langS.activeLang] }}</span>
                <div *ngIf="category.children.length" #el [ngClass]="'subcategories ' + getSubPosition(el)">
                    <div class=" sub-list">
                        <span *ngFor="let subcat of category.children" routerLink="{{ '/category/' + subcat.id }}"
                            routerLinkActive="active" class="item">{{ subcat.title[langS.activeLang] }}</span>
                    </div>

                    <figure *ngIf="category.imgBig.url" class="category-preview">
                        <img [src]="settings.getImgSrc(category.imgBig)" [alt]="category.imgBig.alt[langS.activeLang]"
                            alt="" />
                    </figure>
                </div>
            </div>
        </div>
    </div>

    <div class="content">
        <router-outlet></router-outlet>
    </div>

</div>

<ys-modal *ngIf="settings.isShowLogin" class="login-modal" [header]="langS.translate('login')"
    [(visible)]="settings.isShowLogin">
    <app-login *ngIf="settings.isShowLogin"></app-login>
</ys-modal>

<ys-modal *ngIf="settings.isShowRegister" class="login-modal register-modal" [header]="langS.translate('register')"
    [(visible)]="settings.isShowRegister">
    <app-register *ngIf="settings.isShowRegister"></app-register>
</ys-modal>

<footer class="footer">
    <div class="footer-content ">
        <div class="footer-sections box">
            <div *ngIf="settings.contacts" class="section contacts">
                <h2>{{langS.translate('contacts')}}</h2>
                <div class="info-item">
                    <span language="address" class="label">{{ langS.translate("address") }}:</span>
                    <span class="value">{{ settings.contacts[0].address }}</span>
                </div>
                <div class="info-item">
                    <div>
                        <span class="label">{{ langS.translate("phone") }}:</span>
                        <span class="value"> {{ settings.contacts[0].phone }}</span>
                    </div>
                </div>
                <div class="info-item">
                    <div>
                        <span class="label">E-mail:</span>
                        <span class="value"> {{ settings.contacts[0].email }}</span>
                    </div>
                </div>
                <div class="info-item work-schedule">
                    <span class="label">{{ langS.translate("workTime") }}:</span>
                    <span class="value">{{ settings.contacts[0].work_days }}</span>
                </div>

                <div class="social">
                    <div class="social-btn"></div>
                </div>
            </div>
            <div class="section information">
                <h2>{{langS.translate('info')}}</h2>
                <a routerLink="/page/about-us" routerLinkActive="active" class="item">{{
                    langS.translate("aboutUs")
                    }}</a>
                <a routerLink="/page/terms-and-conditions" routerLinkActive="active"
                    class="item">{{langS.translate("termNcon")}}</a>
                <a routerLink="/contacts" routerLinkActive="active" class="item">{{
                    langS.translate("contacts")
                    }}</a>


                <a routerLink="/page/warranty" routerLinkActive="active" class="item">{{
                    langS.translate("warranty")
                    }}</a>
                <a routerLink="/page/privacy-policy" routerLinkActive="active" class="item">{{
                    langS.translate("privacy-policy")
                    }}</a>
                <a routerLink="/page/payment-ways" routerLinkActive="active" class="item">{{
                    langS.translate("payment-ways")
                    }}</a>
                <a routerLink="/page/transport" routerLinkActive="active" class="item">{{
                    langS.translate("transport")
                    }}</a>
                <a routerLink="/page/keep-and-usage-guide" routerLinkActive="active" class="item">{{
                    langS.translate("keep-and-usage-guide")
                    }}</a>
            </div>
            <div class="section socials">
                <div *ngIf="settings.contacts" class="social-block">
                    <h2>{{langS.translate('socials')}}</h2>
                    <div class="social">
                        <a *ngIf="settings.contacts[1].socials.facebook" [href]="settings.contacts[1].socials.facebook"
                            target="_blank" class="item">
                            <i class="fa-brands fa-facebook-f"></i>
                        </a>
                        <a *ngIf="settings.contacts[1].socials.instagram"
                            [href]="settings.contacts[1].socials.instagram" target="_blank" class="item">
                            <i class="fa-brands fa-instagram"></i>
                        </a>
                        <a *ngIf="settings.contacts[1].socials.playmarket"
                            [href]="settings.contacts[1].socials.playmarket" target="_blank" class="item">
                            <i class="fa-brands fa-google-play"></i>
                        </a>
                        <a *ngIf="settings.contacts[1].socials.twitter" [href]="settings.contacts[1].socials.twitter"
                            target="_blank" class="item">
                            <i class="fa-brands fa-twitter"></i>
                        </a>
                        <a *ngIf="settings.contacts[1].socials.youtube" [href]="settings.contacts[1].socials.youtube"
                            target="_blank" class="item">
                            <i class="fa-brands fa-youtube"></i>
                        </a>
                        <a *ngIf="settings.contacts[1].socials.pinterest"
                            [href]="settings.contacts[1].socials.pinterest" target="_blank" class="item">
                            <i class="fa-brands fa-pinterest-p"></i>
                        </a>
                        <a *ngIf="settings.contacts[1].socials.vk" [href]="settings.contacts[1].socials.vk"
                            target="_blank" class="item">
                            <i class="fa-brands fa-vk"></i>
                        </a>
                        <a *ngIf="settings.contacts[1].socials.ok" [href]="settings.contacts[1].socials.ok"
                            target="_blank" class="item">
                            <i class="fa-brands fa-odnoklassniki"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="second-line">
        <div class="rights">
            © 2022 Mobiladevis.md. All Rights Reserved.
        </div>
        <div class="developed-by">
            <figure class="logo">
                <img src="../assets/images/it_lab.png" alt="" />
            </figure>
            <span class="title">{{ langS.translate("developedbyItLab") }}</span>
        </div>
    </div>
</footer>