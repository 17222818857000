import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'ys-modal',
    templateUrl: './ys-modal.component.html',
    styleUrls: ['./ys-modal.component.scss'],
})
export class YsModalComponent implements OnInit {
    @Input('header') headerTitle: any;

    visibleValue = false;
    @Output() visibleChange = new EventEmitter();
    @Output('onClose') onClose = new EventEmitter();
    @Input('isLoader') isLoader = false;

    @Input()
    get visible() {
        return this.visibleValue;
    }

    set visible(val) {
        this.visibleValue = val;
        this.visibleChange.emit(this.visibleValue);
    }

    constructor() { }

    ngOnInit() {

    }

    hideModal(e: any = null) {
        if (e) e.stopPropagation();

        this.onClose.emit(true);
        this.visible = this.isLoader || false;
    }
}
