import { FavoritesPageComponent } from './_pages/favorites-page/favorites-page.component';
import { AuthGuard } from './_guards/auth.guard';
import { CartPageComponent } from './_pages/cart-page/cart-page.component';
import { ContactsPageComponent } from './_pages/contacts-page/contacts-page.component';
import { ProductsListPageComponent } from './_pages/products-list-page/products-list-page.component';
import { ProductPageComponent } from './_pages/product-page/product-page.component';
import { HomePageComponent } from './_pages/home-page/home-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsPageComponent } from './_pages/aboutus-page/aboutus-page.component';

const routes: Routes = [
    {
        path: '',
        component: HomePageComponent,
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('./_components/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'user',
        loadChildren: () =>
            import('./_pages/user/user.module').then((m) => m.UserModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'product/:id',
        component: ProductPageComponent,
    },
    {
        path: 'category/:id',
        component: ProductsListPageComponent,
    },
    {
        path: 'category/:id/:page',
        component: ProductsListPageComponent,
    },
    {
        path: 'contacts',
        component: ContactsPageComponent,
    },
    // {
    //     path: 'about-us',
    //     component: AboutUsPageComponent,
    // },
    {
        path: "page/:page",
        component: AboutUsPageComponent,
    },
    {
        path: 'cart',
        component: CartPageComponent,
    },
    {
        path: 'favorites',
        component: FavoritesPageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabledBlocking'
    })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
