import { FavoritesService } from './../../_services/favorites.service';
import { AuthService } from './../../_services/auth.service';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { SsrService } from './../../_services/ssr.service';
import { ProductsCategoriesService } from './../../_services/products-categories.service';
import { LanguageService } from 'src/app/_services/internationality/language.service';
import { QueriesService } from './../../_services/queries.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from 'src/app/_services/settings.service';
import {
    Product,
    ProductsCategory,
    CutedProduct,
} from './../../_models/productsAndCategories';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-favorites-page',
    templateUrl: './favorites-page.component.html',
    styleUrls: ['./favorites-page.component.scss'],
})
export class FavoritesPageComponent implements OnInit {
    products: any[] = [];

    constructor(
        public _settings: SettingsService,
        private httpClient: HttpClient,
        private queriesS: QueriesService,
        public langS: LanguageService,
        public _sanitaizer: DomSanitizer,
        private router: Router,
        private authS: AuthService,
        private favoriteS: FavoritesService,
        public settingsS: SettingsService,
        private ssrS: SsrService
    ) { }

    ngOnInit(): void {
        if (!this.authS.isAuth) {
            if (this.ssrS.isBrowser) {
                var productsIds = this.favoriteS.getFavoritesFromLocalStorage();
            }
        } else {
            productsIds = this.favoriteS.productsIds;
        }

        this.httpClient
            .get(this.queriesS.URL.favorites, {
                params: { favorites: JSON.stringify(productsIds) },
            })
            .subscribe((products: any) => {
                this.products = products;
                if (!this.authS.isAuth) {
                    if (this.ssrS.isBrowser) {
                        localStorage.setItem(
                            'favorites',
                            JSON.stringify(products.map((item: any) => item.id))
                        );
                    }
                }
            });
    }

}
