<div *ngIf="data" class="wrapper">
    <app-slider [slider]="data.slider.slides"></app-slider>

    <!-- <p-carousel class="carousel" [value]="images" [circular]="true">
    <ng-template let-image pTemplate="item">
      <figure class="slide">
        <img [src]="image" alt="" />
      </figure>
    </ng-template>
  </p-carousel> -->

    <div class="products-1">
        <div class="content">
            <div class="info">
                <h2 class="title">
                    {{ data.featured_products.title[langS.activeLang] }}
                </h2>
                <p class="description">
                    {{ data.featured_products.description[langS.activeLang] }}
                </p>
            </div>

            <div class="featured-products">
                <div *ngFor="let product of data.featured_products.products" class="product"
                    [routerLink]="'/product/' + product.id">
                    <figure class="image">
                        <img [src]="settingsS.getImgSrc(product.mainImage)" alt="" />
                        <!-- <div class="add-to-cart" (click)="addToCart($event,product)">
                             <span class="add-to-cart-text">{{langS.translate('addBasket')}}</span><span
                                class="cart fa-solid fa-cart-shopping"></span>
                        </div> -->
                    </figure>
                    <div class="info">
                        <span class="title">{{ product.title[langS.activeLang] }}</span>
                        <!-- <span class="price">{{ settingsS.priceFormat(getProductPrice(product, true))}}
                            MDL</span> -->
                        <span class="price" [class.is-sale]="product.sale > 0">
                            <div class="price-tag">

                                <span class="actual-price">
                                    {{ settingsS.getProductPriceTag(true,product.variants[0],product.sale) }} MDL
                                </span> <br>
                                <span *ngIf="product.sale > 0" class="old-price">
                                    ({{ settingsS.getProductPriceTag(false,product.variants[0],product.sale) }} MDL)
                                </span>
                            </div>
                            <span (click)="addToCart($event,product)" [title]="langS.translate('addBasket')"
                                class="cart fa-solid fa-cart-shopping"></span>
                        </span>
                    </div>
                </div>
            </div>

            <div *ngIf="data.grid_products.products.length >= 9" class="grid-products">
                <div class="info">
                    <h2 *ngIf="data.grid_products.title[langS.activeLang]" class="title">
                        {{ data.grid_products.title[langS.activeLang] }}
                    </h2>
                    <p *ngIf="data.grid_products.description[langS.activeLang]" class="description">
                        {{ data.grid_products.description[langS.activeLang] }}
                    </p>

                </div>
                <div class="first-line">
                    <div [routerLink]="'/product/' + data.grid_products.products[0].id" class="main-item sale-item">
                        <span class="price" [class.is-sale]="data.grid_products.products[0].sale > 0">
                            <span class="price">
                                <span class="actual-price">
                                    {{
                                    settingsS.getProductPriceTag(true,data.grid_products.products[0].variants[0],data.grid_products.products[0].sale)

                                    }} MDL </span> <br>
                                <span *ngIf="data.grid_products.products[0].sale > 0" class="old-price">
                                    ({{
                                    settingsS.getProductPriceTag(false,data.grid_products.products[0].variants[0],data.grid_products.products[0].sale)
                                    }} MDL)
                                </span>
                            </span>
                        </span>
                        <figure class="image">

                            <img [src]="settingsS.getImgSrc(data.grid_products.products[0].mainImage)" alt="" />
                            <!-- <div class="add-to-cart"
                                (click)="addToCart($event,data.grid_products.products[0].variants[0])">
                                <span class="add-to-cart-text">{{langS.translate('addBasket')}}</span><span
                                    class="cart fa-solid fa-cart-shopping"></span>
                            </div> -->
                        </figure>
                        <div class="info">
                            <span class="title">{{ data.grid_products.products[0].title[langS.activeLang] }}</span>
                            <!-- <span class="price">{{ settingsS.priceFormat(getProductPrice(product, true))}}
                                MDL</span> -->
                            <span (click)="addToCart($event,data.grid_products.products[0])"
                                [title]="langS.translate('addBasket')" class="cart fa-solid fa-cart-shopping"></span>

                        </div>
                    </div>
                    <div class="items">
                        <div *ngFor="let item of data.grid_products.products.slice(1, 5)"
                            [routerLink]="'/product/' + item.id" class="item sale-item">

                            <span class="price" [class.is-sale]="item.sale > 0">
                                <span class="actual-price">

                                    {{ settingsS.getProductPriceTag(true,item.variants[0],item.sale) }} MDL</span>
                                <br>
                                <span *ngIf="item.sale > 0" class="old-price">
                                    ({{ settingsS.getProductPriceTag(false,item.variants[0],item.sale) }} MDL)
                                </span>
                            </span>
                            <figure class="image">
                                <img [src]="settingsS.getImgSrc(item.mainImage)" [alt]="item.title[langS.activeLang]" />
                                <!-- <div class="add-to-cart" (click)="addToCart($event,item)">

                                    <span class="add-to-cart-text">{{langS.translate('addBasket')}}</span><span
                                        class="cart fa-solid fa-cart-shopping"></span>
                                </div> -->
                            </figure>
                            <div class="info">
                                <span class="title">{{ item.title[langS.activeLang] }}</span>
                                <!-- <span class="price">{{ settingsS.priceFormat(getProductPrice(product, true))}}
                                    MDL</span> -->
                                <span (click)="addToCart($event,item)" [title]="langS.translate('addBasket')"
                                    class="cart fa-solid fa-cart-shopping"></span>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="second-line items">
                    <div *ngFor="let item of data.grid_products.products.slice(5, 9)" class="item sale-item"
                        [routerLink]="'/product/' + item.id">
                        <span class="price" [class.is-sale]="item.sale > 0">
                            <span class="actual-price">
                                {{ settingsS.getProductPriceTag(true,item.variants[0],item.sale) }} MDL</span> <br>
                            <span *ngIf="item.sale > 0" class="old-price">
                                ({{ settingsS.getProductPriceTag(false,item.variants[0],item.sale) }} MDL)
                            </span>
                        </span>
                        <figure class="image">
                            <img [src]="settingsS.getImgSrc(item.mainImage)" [alt]="item.title[langS.activeLang]" />
                            <!-- <div class="add-to-cart" (click)="addToCart($event,item)">
                                <span class="add-to-cart-text">{{langS.translate('addBasket')}}</span><span
                                    class="cart fa-solid fa-cart-shopping"></span>
                            </div> -->
                        </figure>
                        <div class="info">
                            <span class="title">{{ item.title[langS.activeLang] }}</span>
                            <!-- <span class="price">{{ settingsS.priceFormat(getProductPrice(product, true))}}
                                MDL</span> -->
                            <span (click)="addToCart($event,item)" [title]="langS.translate('addBasket')"
                                class="cart fa-solid fa-cart-shopping"></span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>