import { DomSanitizer, Meta } from '@angular/platform-browser';
import { SsrService } from './../../_services/ssr.service';
import { ProductsCategoriesService } from './../../_services/products-categories.service';
import { LanguageService } from 'src/app/_services/internationality/language.service';
import { QueriesService } from './../../_services/queries.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from 'src/app/_services/settings.service';
import {
    Product,
    ProductsCategory,
    CutedProduct,
} from './../../_models/productsAndCategories';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CartService } from '../../_services/cart.service';
import { YsToastService } from '../../_services/ys-toast.service';

@Component({
    selector: 'app-products-list-page',
    templateUrl: './products-list-page.component.html',
    styleUrls: ['./products-list-page.component.scss'],
})
export class ProductsListPageComponent implements OnInit {
    routerParamsChangeSubscription: Subscription;
    routerScrollPos: Subscription;
    saveScrollOnNav: Subscription;
    selectedCategory: ProductsCategory;

    showFilters = false;

    selectedPage = 1;
    pagesQuantity = 1;
    filtersApplied: any[] = [];

    topScroll = 0;

    products: any[] = [];

    noUpdateOnNextChangeUrlParams = false;

    constructor(
        public _settings: SettingsService,
        private httpClient: HttpClient,
        private queriesS: QueriesService,
        private activRoute: ActivatedRoute,
        public langS: LanguageService,
        private productsCategoriesS: ProductsCategoriesService,
        private ssrS: SsrService,
        public _sanitaizer: DomSanitizer,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private meta: Meta,
        public cartS: CartService,
        private ysToast: YsToastService
    ) { }


    ngOnInit(): void {
        this.routerParamsChangeSubscription = this.activRoute.params.subscribe(
            async (params: Params) => {
                if (this.noUpdateOnNextChangeUrlParams)
                    return (this.noUpdateOnNextChangeUrlParams = false);

                this.selectedPage = +params['page'] || 1;
                if (this.ssrS.isBrowser) window.scrollTo(0, 0);

                if (!this.selectedCategory || +params.id !== this.selectedCategory.id) {
                    await Promise.all([
                        this.updateSelectedCategory(params.id),
                        this.updateProductsList(params.id, []),
                    ]);
                } else {
                    this.updateProductsList();
                }

                return '';
            }
        );
    }

    ngOnDestroy() {
        // this.saveScrollOnNav.unsubscribe();
        this.routerParamsChangeSubscription.unsubscribe();
    }

    addFilter(filter: any, value: any) {
        let FOI = this.filtersApplied.find(e => e.id == filter.id);
        if (!FOI)
            this.filtersApplied.push({ id: filter.id, values: [] });
        FOI = this.filtersApplied.find(e => e.id == filter.id);
        let FOIidx = FOI.values.findIndex((e: any) => e.data == value.data);
        if (FOIidx < 0) {
            FOI.values.push(value);
        } else {
            FOI.values.splice(FOIidx, 1);
        }
    }

    filterIsApplied(filter: any, value: any) {
        let FOI = this.filtersApplied.find(e => e.id == filter.id);
        if (!FOI)
            return false;
        FOI = this.filtersApplied.find(e => e.id == filter.id);
        let FOIidx = FOI.values.findIndex((e: any) => e.data == value.data);
        if (FOIidx < 0)
            return false;

        return true;
    }

    async updateSelectedCategory(categoryIdFromParams: string) {
        const category = await this.httpClient
            .get<ProductsCategory>(this.queriesS.URL.productsCategory, {
                params: { mode: 'one', id: categoryIdFromParams },
            })
            .toPromise();

        this.selectedCategory = category;
        this._settings.applySinitizer(this.selectedCategory.description);

        // if (category.parentCategoryId) {
        //     const subscription = this.productsCategoriesS.onCategoriesReceived.subscribe((value) => {
        //         if (!value) return;
        //         const parentCategory = this.productsCategoriesS.rootCategories.find((item) => item.id === category.parentCategoryId);
        //         this._settings.breadcrumb.unshift({
        //             title: parentCategory.title,
        //             url: `/catalogue/list/${parentCategory.id}/1`
        //         });
        //         setTimeout(() => subscription.unsubscribe());
        //     });
        // }
    }

    async updateProductsList(
        categoryId = this.selectedCategory.id,
        //filters = this.selectedCategory.filters
        filters = this.filtersApplied
    ) {
        const data = await this.httpClient
            .get<{
                pagesQuantity: number;
                selectedPage: number;
                products: CutedProduct[];
            }>(this.queriesS.URL.product, {
                params: {
                    mode: 'byFilters',
                    categoryId: categoryId + '',
                    filters: JSON.stringify(
                        filters
                            // .map((filter) => {
                            //     return {
                            //         id: filter.id,
                            //         values: filter.values
                            //             .filter((value) => value.selected)
                            //             .map((value) => value.data),
                            //     };
                            // })
                            .filter((filter) => filter.values.length)
                    ),
                    page: this.selectedPage + '',
                },
            })
            .toPromise();
        this.pagesQuantity = data.pagesQuantity;
        if (this.selectedPage !== data.selectedPage) {
            this.selectedPage = data.selectedPage;
            this.noUpdateOnNextChangeUrlParams = true;
            setTimeout(() => {
                this.router.navigate([
                    '/',
                    'category',
                    this.selectedCategory.id,
                    this.selectedPage,
                ]);
            });
        }
        this.products = data.products;

        this.cdr.detectChanges();
        window.scrollTo({ top: history.state.scrollTop });
    }

    async changePage(page: number) {
        setTimeout(() => {
            this.router.navigate(['/', 'category', this.selectedCategory.id, page]);
        });
    }

    // getProductPrice(product: any, withSale: boolean = true) {
    //     let price: any = '--';
    //     if (product.variants && product.variants.length > 0) {
    //         price = product.variants[0].price;

    //         if (withSale && product.sale > 0) {
    //             price = price - (product.sale / 100) * price;
    //         }
    //     }

    //     return price;
    // }

    addToCart(event: Event, product: any) {
        event.stopPropagation();
        this.cartS.addToCart(
            product.id,
            product.variants[0].id,
            1,
            product.variants[0].numberInStock,
        );

        this.ysToast.showMessage({
            title: this.langS.translate('success'),
            text: this.langS.translate('isAddedToCart'),
            typeToast: 'success',
            duration: 2000,
        });
    }
}
