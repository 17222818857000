import { CartService } from './../../../_services/cart.service';
import { FavoritesService } from './../../../_services/favorites.service';
import { SettingsService } from 'src/app/_services/settings.service';
import { LanguageService } from './../../../_services/internationality/language.service';
import { YsToastService } from './../../../_services/ys-toast.service';
import { AuthService } from 'src/app/_services/auth.service';
import { Router } from '@angular/router';
import { trimValidator } from 'src/app/_utils/formValidationUtils';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { setCookie } from '../../../_utils/cookieUtils';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    loginForm = new FormGroup({
        email: new FormControl('', [
            Validators.required,
            Validators.maxLength(128),
            trimValidator,
            Validators.email,
        ]),
        password: new FormControl('', [
            Validators.required,
            Validators.maxLength(16),
            Validators.minLength(6),
        ]),
    });

    serverError = '';

    constructor(
        private auth: AuthService,
        private router: Router,
        private _toast: YsToastService,
        public langS: LanguageService,
        public settingsS: SettingsService,
        private favoriteS: FavoritesService,
        private cartS: CartService
    ) { }

    ngOnInit(): void { }

    login() {
        this.loginForm.markAllAsTouched();
        this.loginForm.updateValueAndValidity();

        if (this.loginForm.valid) {
            this.auth
                .login(
                    this.loginForm.controls.email.value,
                    this.loginForm.controls.password.value
                )
                // @ts-ignore
                .then(
                    (data: any) => {
                        this.loginForm.enable();
                        // if (!!data)
                        // this.router.navigate(['auth', 'confirm-email']);
                        this.settingsS.isShowLogin = false;
                        this.favoriteS.updateFavorites();
                        this.cartS.updateCart();
                    },
                    (err: any) => {
                        if (err.type == 'userIsBlock')
                            this._toast.showMessage({
                                title: 'Error',
                                text: 'Your account has been blocked, please contact support!',
                                typeToast: 'error',
                                duration: 5000,
                            });
                        else if (err.type == 'invalidEmailOrPassword')
                            this.serverError = 'invalidEmailOrPasswordError';
                        else if (err.type == 'userNotIsActive')
                            this.serverError = 'userNotIsActiveError';

                        this.loginForm.enable();
                    }
                );

            this.loginForm.disable();
        };
    }
}
