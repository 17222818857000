import { LanguageService } from 'src/app/_services/internationality/language.service';
// import { SsrService } from './../../_services/ssr.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {
    passwordConfirmValidatorPartPassword,
    passwordConfirmValidatorPartPasswordConfirm,
    trimValidator,
} from 'src/app/_utils/formValidationUtils';
import { SettingsService } from '../../../_services/settings.service';
import { SsrService } from '../../../_services/ssr.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    passwordForm = new FormGroup({
        newPassword: new FormControl('', [
            Validators.required,
            Validators.maxLength(16),
            Validators.minLength(6),
        ]),
    });

    constructor(
        private auth: AuthService,
        public langS: LanguageService,
        private ssrS: SsrService,
        private router: Router // private ssr: SsrService
    ) { }

    ngOnInit(): void {
        this.passwordForm.addControl(
            'repeatPassword',
            new FormControl('', [
                // @ts-ignore
                passwordConfirmValidatorPartPasswordConfirm.bind(
                    this,
                    // @ts-ignore
                    this.passwordForm.controls.newPassword
                ),
            ])
        );
        this.passwordForm.controls.newPassword.setValidators([
            Validators.required,
            Validators.maxLength(128),
            Validators.minLength(6),
            // @ts-ignore
            trimValidator,
            // @ts-ignore
            passwordConfirmValidatorPartPassword.bind(
                this,
                // @ts-ignore
                this.passwordForm.controls.repeatPassword
            ),
        ]);
    }

    changePassword() {
        if (this.passwordForm.invalid) return;

        this.auth
            .endChangeForgotPassword(this.passwordForm.controls.newPassword.value)
            // @ts-ignore
            .subscribe((data) => {
                let msg = JSON.parse(data.headers.get('Show-Message'));
                let respMsg = msg.description.key;
                if (respMsg == 'passwordHasChanged') {
                    if (this.ssrS.isBrowser) {
                        localStorage.setItem('token', data.headers.get('Set-token'));
                    }
                    this.router.navigate(['user', 'settings']);
                }
            });

        this.passwordForm.disable();
    }
}
