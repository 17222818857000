<div class="content">
  <div *ngIf="respMsg != 'forgotPasswordStep1Msg'" class="form">
    <h1 class="title">{{ langS.translate("resetPassword") }}</h1>
    <div class="description">
      {{ langS.translate("forgotPasswordStep1Msg") }}
    </div>
    <div class="form">
      <div class="input-field">
        <label>E-mail</label>
        <input
          [(ngModel)]="email"
          (blur)="isValidate = true"
          (focus)="respMsg = ''"
          type="text"
          placeholder="email"
        />
        <div *ngIf="!validateEmail(email) && isValidate" class="error">
          {{ langS.translate("invalidEmailError") }}
        </div>
        <div *ngIf="respMsg == 'userNotExistsError'" class="error">
          {{ langS.translate("userNotExistsError") }}
        </div>
        <div *ngIf="respMsg == 'userIsBlockError'" class="error">
          {{ langS.translate("userIsBlockError") }}
        </div>
        <div *ngIf="respMsg == 'userNotIsActiveError'" class="error">
          {{ langS.translate("userNotIsActiveError") }}
        </div>
      </div>

      <button
        [disabled]="!validateEmail(email) || isWait"
        (click)="startReset()"
        class="btn form__btn"
      >
        {{ langS.translate("send") }}
      </button>
    </div>
  </div>
  <div *ngIf="respMsg == 'forgotPasswordStep1Msg'" class="form">
    <h1 class="title">
      {{ langS.translate("confirmChangePass") }}
    </h1>
  </div>
</div>
