import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CutedProductsCategory } from '../_models/productsAndCategories';
import { QueriesService } from './queries.service';

@Injectable({ providedIn: 'root' })
export class ProductsCategoriesService {
    allCategories: CutedProductsCategory[] = [];
    rootCategories: CutedProductsCategory[] = [];

    onCategoriesReceived = new BehaviorSubject(false);

    constructor(
        private httpClient: HttpClient,
        private queriesS: QueriesService
    ) {
        this.httpClient
            .get(this.queriesS.URL.productsCategory, { params: { mode: 'all' } })
            .subscribe((categories: any) => {
                this.allCategories = categories;
                this.rootCategories = categories.filter(
                    (item: any) => !item.parentCategoryId
                );
                for (let category of this.allCategories)
                    category.children = this.allCategories.filter(
                        (item) => item.parentCategoryId === category.id
                    );
                this.onCategoriesReceived.next(true);
            });
    }
}
