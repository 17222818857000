<div *ngIf="product" class="wrapper">
    <div class="top-block">
        <div class="gallery">
            <p-galleria class="product-galleria" [value]="product.sizesImage" [circular]="true"
                [showItemNavigators]="true" [responsiveOptions]="responsiveOptions" [numVisible]="4">
                <ng-template pTemplate="item" let-item>
                    <figure class="image">
                        <img [src]="settingsS.getImgSrc(item)" [alt]="item.alt" />
                    </figure>
                </ng-template>
                <ng-template pTemplate="thumbnail" let-item>
                    <div class="thumb">
                        <img [src]="settingsS.getImgSrc(item)" />
                    </div>
                </ng-template>
            </p-galleria>
        </div>

        <div class="product-info">
            <div class="nav">
                Home / Products / {{ product.category.title[langS.activeLang] }}
            </div>

            <h3 class="title">{{ product.title[langS.activeLang] }}</h3>
            <div class="separator"></div>

            <div *ngIf="product.variants && product.variants.length > 1" class="variants">
                <div *ngFor="let variant of product.variants" (click)="selectVariant(variant)" class="item"
                    [class.active]="activeVariant && activeVariant.id === variant.id">
                    <span class="title">{{ variant.title[langS.activeLang] }}</span>
                </div>
            </div>

            <div *ngIf="product.variants && product.variants.length > 1" class="separator"></div>

            <div class="additional-info">
                <span class="price" [class.is-sale]="activeVariant.sale > 0">
                    {{ settingsS.getProductPriceTag(true,activeVariant,product.sale) }} MDL
                    <span *ngIf="product.sale > 0" class="old-price">
                        ({{ settingsS.getProductPriceTag(false,activeVariant,product.sale) }} MDL)
                    </span>
                </span>
                <!-- <div class="ratting">
          <i class="fa-regular fa-star active"></i>
          <i class="fa-regular fa-star active"></i>
          <i class="fa-regular fa-star active"></i>
          <i class="fa-regular fa-star active"></i>
          <i class="fa-regular fa-star"></i>
        </div>
        <span class="reviews">0 reviews</span> -->
            </div>

            <div class="func-block">
                <div class="count-block">
                    <button (click)="setProductCount('minus')" class="btn-light">
                        <i class="fa-solid fa-minus"></i>
                    </button>
                    <div class="count">{{ productQuantity }}</div>
                    <button (click)="setProductCount('plus')" class="btn-light">
                        <i class="fa-solid fa-plus"></i>
                    </button>
                </div>
                <button (click)="addToCart()" class="btn-light buy-btn">
                    {{langS.translate('addBasket')}}
                </button>
                <button (click)="favoriteS.changeInFavoritesStatus(product.id)" class="btn-light"
                    [class.is-fav]="favoriteS.productInFavorites(product.id)">
                    <i class="fa-solid fa-heart"></i>
                </button>
                <!-- <button class="btn-light">
          <i class="fa-solid fa-repeat"></i>
        </button> -->
            </div>

            <div [innerHTML]="product.shortDescription[langS.activeLang]" class="description"></div>

            <!-- <div class="features-list">
                <div class="feature">
                    <span class="title">Features and materials</span>
                    <span class="description">Black<br />
                        Wood</span>
                </div>

                <div class="feature">
                    <span class="title">Dimesions</span>
                    <span class="description">48cm x 70cm x 23cm</span>
                </div>

                <div class="feature">
                    <span class="title"></span>
                    <span class="description">SKU: 234234<br />Category: Accessories, Chair</span>
                </div>
            </div> -->

            <!-- <div class="social">
        <button class="btn-light btn-social">Facebook</button>
        <button class="btn-light btn-social">Twitter</button>
        <button class="btn-light btn-social">Pinterest</button>
      </div> -->
        </div>
    </div>

    <div class="info-block">
        <div class="tabs-block">
            <div class="tabs-header">
                <div (click)="activeInfoTab = 'description'" class="tab"
                    [class.active]="activeInfoTab == 'description'">
                    Description
                </div>
                <div (click)="activeInfoTab = 'additional'" class="tab" [class.active]="activeInfoTab == 'additional'">
                    Additional info
                </div>
                <!-- <div
          (click)="activeInfoTab = 'review'"
          class="tab"
          [class.active]="activeInfoTab == 'review'"
        >
          Review
        </div> -->
            </div>

            <div class="tab-content">
                <div *ngIf="activeInfoTab == 'description'" [innerHTML]="product.description[langS.activeLang]"
                    class="description"></div>
                <div *ngIf="activeInfoTab == 'additional'" class="additional-info">
                    <div *ngFor="let filter of product.filters" class="info-item">
                        <span class="label">{{ filter.name[langS.activeLang] }}</span>
                        <span class="valuen">{{ filter.value }}</span>
                    </div>
                </div>
                <!-- <div *ngIf="activeInfoTab == 'review'" class="review">
          ;lsakd ;laksdl; askdl;kas ;dlkasl;dkaslf;k sdl;fkdj;g ;fdlk;df
          jl;dfjl;kdfjl;kdfjg lk;dfjgl;kdflk;dfjdf ;lk;fjglk;df ld;fjflk;dfgdk
          jdflk;gdflk;gdflk;gjdfk;g jdfl;jgdlf; gjdfg;lkjdf
          g;ldkjgl;kdfjdlfk;gdf ;lgkjgfd ;lgkjdfg;lkj
        </div> -->
            </div>
        </div>
    </div>

    <div class="like-products">
        <div class="zigzag-block">
            <div class="zigzag"></div>
            <div class="zigzag"></div>
        </div>

        <div class="info">
            <h2 class="title">Like products</h2>
            <p class="description">See all</p>
        </div>

        <div class="products-list">
            <div *ngFor="let pr of similarProducts" class="product">
                <figure class="image">
                    <img [src]="settingsS.getImgSrc(pr.mainImage)" alt="" />
                </figure>

                <span class="title">{{ pr.title[langS.activeLang] }}</span>
                <span class="price">{{ pr.price }} MDL</span>
            </div>
        </div>
    </div>
</div>