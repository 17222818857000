import { FavoritesPageComponent } from './_pages/favorites-page/favorites-page.component';
import { FormsModule } from '@angular/forms';
import { SettingsService } from 'src/app/_services/settings.service';
import { YsModalModule } from './_components/ys-modal/ys-modal.module';
import { CartPageComponent } from './_pages/cart-page/cart-page.component';
import { LanguageService } from './_services/internationality/language.service';
import { AboutUsPageComponent } from './_pages/aboutus-page/aboutus-page.component';
import { ContactsPageComponent } from './_pages/contacts-page/contacts-page.component';
import { SharedModule } from './_components/shared.module';
import { ProductsListPageComponent } from './_pages/products-list-page/products-list-page.component';
import { ProductsCategoriesService } from './_services/products-categories.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './_pages/home-page/home-page.component';
import { CarouselModule } from 'primeng/carousel';
import { ProductPageComponent } from './_pages/product-page/product-page.component';
import { GalleriaModule } from 'primeng/galleria';
import { AuthModule } from './_components/auth/auth.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './_interceptors/app.interceptor';

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        ProductPageComponent,
        ProductsListPageComponent,
        ContactsPageComponent,
        AboutUsPageComponent,
        CartPageComponent,
        FavoritesPageComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        FormsModule,
        AppRoutingModule,
        CarouselModule,
        GalleriaModule,
        HttpClientModule,
        SharedModule,
        YsModalModule,
        AuthModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        ProductsCategoriesService,
        LanguageService,
        SettingsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppInterceptor,
            multi: true,
        },
    ],
})
export class AppModule { }
