import { LanguageService } from './../../_services/internationality/language.service';
import { SettingsService } from './../../_services/settings.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-contacts-page',
    templateUrl: './contacts-page.component.html',
    styleUrls: ['./contacts-page.component.scss'],
})
export class ContactsPageComponent implements OnInit {
    selectedCity: string = "balti";
    constructor(
        public settings: SettingsService,
        public langS: LanguageService
    ) {
    }

    ngOnInit(): void { }
}
