import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, EventEmitter, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { QueriesService } from './queries.service';
import { SettingsService } from './settings.service';
import { SsrService } from './ssr.service';

@Injectable({ providedIn: 'root' })
export class FavoritesService {
    productsIds: number[] = [];

    constructor(
        private authS: AuthService,
        private httpClient: HttpClient,
        private queriesS: QueriesService,
        private ssrS: SsrService
    ) {
        if (this.ssrS.isBrowser) {
            if (localStorage.getItem('token'))
                this.authS.onUserDataChanged.subscribe(() => {
                    this.updateFavorites();
                });
            else this.updateFavorites();
        }
    }

    onChangeInFavoritesStatus = new EventEmitter<number>();

    getFavoritesFromLocalStorage() {
        try {
            var productsIds = JSON.parse(localStorage.getItem('favorites'));
            if (!Array.isArray(productsIds)) throw 'not is array';
        } catch {
            productsIds = [];
            localStorage.setItem('favorites', '[]');
        }
        return productsIds;
    }

    updateFavorites() {
        if (this.ssrS.isBrowser) {
            if (this.authS.isAuth) {
                this.productsIds = this.authS.user.favorites;
            } else {
                this.productsIds = this.getFavoritesFromLocalStorage();
            }
        }
    }

    productInFavorites(productId: number) {
        return this.productsIds.indexOf(productId) !== -1;
    }

    changeInFavoritesStatus(productId: number) {
        this.onChangeInFavoritesStatus.emit(productId);
        if (this.productInFavorites(productId)) {
            if (this.authS.isAuth) {
                this.productsIds = this.productsIds.filter((id) => id !== productId);
                this.httpClient
                    .delete(this.queriesS.URL.favorites, {
                        params: { id: productId + '' },
                    })
                    .subscribe((ids: any) => {
                        this.productsIds = ids;
                    });
            } else {
                if (this.ssrS.isBrowser) {
                    this.productsIds = this.getFavoritesFromLocalStorage();
                    this.productsIds = this.productsIds.filter((id) => id !== productId);
                    localStorage.setItem('favorites', JSON.stringify(this.productsIds));
                }
            }
        } else {
            if (this.authS.isAuth) {
                this.productsIds.unshift(productId);
                this.httpClient
                    .put(this.queriesS.URL.favorites, { id: productId })
                    .subscribe((ids: any) => {
                        this.productsIds = ids;
                    });
            } else {
                if (this.ssrS.isBrowser) {
                    this.productsIds = this.getFavoritesFromLocalStorage();
                    this.productsIds.unshift(productId);
                    localStorage.setItem('favorites', JSON.stringify(this.productsIds));
                }
            }
        }
    }

    postLocalFavoritesAfterRegister() {
        return new Promise((resolve: Function) => {
            this.productsIds = this.getFavoritesFromLocalStorage();
            this.httpClient
                .post(this.queriesS.URL.favorites, this.productsIds)
                .subscribe({
                    complete: () => {
                        resolve();
                    },
                });
        });
    }
}
