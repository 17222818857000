export function getCookie(name: string) {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  );
  return matches ? decodeURIComponent(matches[1]) : null;
}

export function deleteCookie(
  name: string,
  options?: { domain?: string; path?: string }
) {
  setCookie(name, '', { 'max-age': -1, ...options });
}

export function setCookie(
  name: string,
  value: string,
  options?: {
    domain?: string; // при указании текущего домена (например site.com или .site.com) cookie будет доступна на поддоменах
    path?: string; // путь по которому доступна кука
    expires?: Date | string; // если не указывать expires или maxAge cookie будет сессионной (до закрытия браузера)
    'max-age'?: number; // тоже что и expires, но количество секунд с момента установки
    eternal?: true; // ставит max-age на 10 лет
    secure?: boolean; // если указанно, то cookie будет передаваться только по HTTPS
    samesite?: 'strict' | 'lax' | 'none';
    // strict - передавать только при запросах с того же домена
    // lax - как strict но за исключением перехода по ссылке (GET, HEAD, OPTIONS, TRACE + должен изменять URL в адресной строке браузера)
  }
) {
  options = {
    path: '/',
    samesite: 'lax',
    ...options,
  };

  if (location.protocol === 'https:') options['secure'] = true; // ставить везде вручную проблематично

  if (options['eternal']) options['max-age'] = 315360000; // на 10 лет

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    if (optionKey === 'eternal') continue;
    updatedCookie += '; ' + optionKey;

    // if (optionKey !== 'secure' && options[optionKey]) {
    //   updatedCookie += '=' + options[optionKey];
    // }
  }

  document.cookie = updatedCookie;
}
