<div class="pagination">
  <div (click)="setPage(selectedPage - 1)" class="arrow">
    <i class="fa-solid fa-arrow-left-long"></i>
  </div>
  <div class="pages">
    <div
      *ngFor="let page of pages"
      (click)="setPage(page)"
      [class.active]="page == selectedPage"
      class="page"
    >
      {{ page }}
    </div>
  </div>
  <div (click)="setPage(selectedPage + 1)" class="arrow">
    <i class="fa-solid fa-arrow-right-long"></i>
  </div>
</div>
