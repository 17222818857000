<div class="wrapper">
    <div class="header">
        <div class="header-content" *ngIf="title">
            <div class="bread">Home / {{ langS.translate(title) }}</div>
            <h2 class="title">{{ langS.translate(title) }}</h2>
        </div>
    </div>

    <div class="content">
        <div *ngFor="let block of blocks; let index = index" [class.info-reverse]="index % 2 !== 0" class="info-block">
            <figure *ngIf="block['image'][langS.activeLang]['url']" class="image">
                <img [src]="settings.getImgSrc(block['image'][langS.activeLang])" alt="" />
            </figure>
            <div class="info">
                <h2 class="title">{{ block["title"][langS.activeLang] }}</h2>
                <span [innerHtml]="block['text'][langS.activeLang]" class="text"></span>
            </div>
        </div>
    </div>
</div>