<div class="wrapper">
    <div class="header">
        <div class="header-content">
            <div class="bread">
                {{ langS.translate("home") }} / {{ langS.translate("contacts") }}
            </div>
            <h2 class="title">{{ langS.translate("contacts") }}</h2>
        </div>
    </div>

    <div *ngIf="settings.contacts" class="content">
        <div class="contacts">
            <div class="city-locations">
                <div class="city" (click)="selectedCity='balti'" [ngClass]="{'_active':selectedCity=='balti'}">{{
                    langS.translate('balti')}}
                </div>
                <div class="city" (click)="selectedCity='edinet'" [ngClass]="{'_active':selectedCity=='edinet'}">{{
                    langS.translate('edinet')}}
                </div>
            </div>
            <hr>
            <div class="info-item">
                <span language="address" class="label">{{ langS.translate("address") }}:</span>
                <span class="value">{{
                    settings.contacts[selectedCity=='balti'?0:selectedCity=='edinet'?1:null].address
                    }}</span>
            </div>
            <div class="info-item">
                <span class="label">{{ langS.translate("phone") }}:</span>
                <span class="value">{{ settings.contacts[selectedCity=='balti'?0:selectedCity=='edinet'?1:null].phone
                    }}</span>
            </div>
            <div class="info-item">
                <span class="label">E-mail:</span>
                <span class="value">{{ settings.contacts[selectedCity=='balti'?0:selectedCity=='edinet'?1:null].email
                    }}</span>
            </div>
            <div class="info-item">
                <span class="label">{{ langS.translate("workTime") }}:</span>
                <span class="value">{{
                    settings.contacts[selectedCity=='balti'?0:selectedCity=='edinet'?1:null].work_days }}</span>
            </div>

            <div class="social">
                <div class="social-btn"></div>
            </div>
        </div>
        <div [innerHtml]="settings.contacts[selectedCity=='balti'?0:selectedCity=='edinet'?1:null].map_link"
            class="map"></div>
    </div>
</div>