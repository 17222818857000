import { SettingsService } from './../../../_services/settings.service';
import { LanguageService } from 'src/app/_services/internationality/language.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  @Input('message') param: any = '';
  msg = '';
  type_msg = 'success';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public langS: LanguageService,
    public settingsS: SettingsService
  ) {}

  ngOnInit(): void {
    if (location.pathname == '/auth/confirm-email')
      this.msg =
        'We need you to confirm your email address. An email with verification instructions has been sent to your email address.';
    else if (location.pathname == '/auth/success')
      this.msg =
        'Congratulations, your email address has been verified, please login using your credentials.';
    else if (location.pathname == '/auth/order-created') {
      this.msg = this.langS.translate('orderCreatedMessage');
      this.type_msg = 'error';
    } else if (location.pathname == '/auth/page-404') {
      this.msg = 'Page not Found!';
      this.type_msg = '404';
    }
  }

  login() {
    this.settingsS.showLogin();
    this.router.navigate(['/']);
  }
}
