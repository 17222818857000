<div class="wrapper">
    <div class="header">
        <div class="header-content">
            <div class="bread">
                {{ langS.translate("home") }} / {{ langS.translate("favorites") }}
            </div>
            <h2 class="title">{{ langS.translate("favorites") }}</h2>
        </div>
    </div>

    <div class="content">
        <div class="products">
            <div class="content">
                <div class="products-list">
                    <div *ngFor="let product of products" [routerLink]="['/product', product.id]" class="product">
                        <figure class="image">
                            <img [src]="_settings.getImgSrc(product.mainImage)"
                                [alt]="product.mainImage.alt[langS.activeLang]" />
                        </figure>

                        <span class="title">{{ product.title[langS.activeLang] }}</span>
                        <!-- <span class="price">{{ product.variants[0].price }} MDL</span> -->
                        <span class="price" [class.is-sale]="product.sale > 0">
                            {{ settingsS.priceFormat(product.variants[0].price) }} MDL
                            <span *ngIf="product.sale > 0" class="old-price">
                                ({{ product.variants[0].price }} MDL)
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--
  <div class="like-products">
    <div class="zigzag-block">
      <div class="zigzag"></div>
      <div class="zigzag"></div>
    </div>

    <div class="info">
      <h2 class="title">Like products</h2>
      <p class="description">See all</p>
    </div>

    <div class="products-list">
      <div *ngFor="let product of [1, 2, 3]" class="product">
        <figure class="image">
          <img src="../../../assets/product/product_1.jpg" alt="" />
        </figure>

        <span class="title">JWDA Concrete Lamp</span>
        <span class="price">340 MDL</span>
      </div>
    </div>
  </div> -->
</div>