<div class="content">
  <div class="form">
    <h1 class="title">{{ langS.translate("resetPassword") }}</h1>
    <div class="description">{{ langS.translate("enterNewPassword") }}</div>

    <form
      [formGroup]="passwordForm"
      novalidate
      (submit)="changePassword()"
      class="form"
    >
      <div for="" class="input-field">
        <label>{{ langS.translate("newPassword") }}</label>
        <input
          formControlName="newPassword"
          type="password"
          placeholder="******"
        />
        <app-validation-error-show
          [control]="passwordForm.controls.newPassword"
        ></app-validation-error-show>
      </div>
      <div for="" class="input-field">
        <label>
          {{ langS.translate("confirmPass") }}
        </label>
        <input
          formControlName="repeatPassword"
          type="password"
          placeholder="******"
        />
        <app-validation-error-show
          [control]="passwordForm.controls.repeatPassword"
        ></app-validation-error-show>
      </div>

      <button
        [disabled]="passwordForm.invalid || passwordForm.disabled"
        class="btn form__btn"
      >
        {{ langS.translate("changePass") }}
      </button>
    </form>
  </div>
</div>
