import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import e from 'express';
import { AuthService } from './auth.service';
import { QueriesService } from './queries.service';
import { SsrService } from './ssr.service';

@Injectable({ providedIn: 'root' })
export class CartService {
    cart: any[] = [];

    constructor(
        private ssrS: SsrService,
        private authS: AuthService,
        private httpClient: HttpClient,
        private queriesS: QueriesService
    ) {
        this.authS.onUserDataChanged.subscribe(() => {
            //console.log('CS')
            if (this.authS.isAuth) {
                this.getCartFromLocalStorage()
                this.cart = this.getAuthUserCart(this.cart) as any;
            } else {
                this.updateCart();
            }
        });
    }

    getAuthUserCart(LScart: any) {
        this.httpClient
            .get(this.queriesS.URL.cart, {
                params: { cart: JSON.stringify(LScart) },
            })
            .subscribe((data: any) => {
                this.cart = data;
                if (!this.authS.isAuth) {
                    if (this.ssrS.isBrowser) {
                        localStorage.setItem(
                            'cart',
                            JSON.stringify(
                                data.map((item: any) => {
                                    return {
                                        id: item.id,
                                        quantity: item.quantity,
                                        id_variant: item.id_variant,
                                        numberInStock: item.variants.find(
                                            (v: any) => v.id === item.id_variant
                                        )?.numberInStock,
                                    };
                                })
                            )
                        );
                    }
                }
            });
    }

    getCartFromLocalStorage() {
        if (this.ssrS.isBrowser) {
            try {
                var cart = JSON.parse(localStorage.getItem('cart'));
                if (!Array.isArray(cart)) throw 'not is array';
            } catch {
                cart = [];
                localStorage.setItem('cart', '[]');
            }
        } else {
            cart = [];
        }
        return cart;
    }

    updateCart() {
        if (this.ssrS.isBrowser) {
            if (!this.authS.isAuth) {
                this.cart = this.getCartFromLocalStorage();
            } else {
                // this.cart = [...this.authS.user.cart];
            }
        }
    }

    clearCart() {
        if (this.ssrS.isBrowser) {
            if (this.authS.isAuth) {
                this.authS.user.cart = [];
            }
            localStorage.setItem('cart', '[]');
        }
        this.cart = [];
    }

    addToCart(
        id: number,
        id_variant: string,
        quantity: number,
        numberInStock: number
    ) {
        if (this.ssrS.isBrowser) {
            if (!this.authS.isAuth) {
                this.cart = this.getCartFromLocalStorage();
                this.cart = this.cart.filter(
                    (item) => !(item.id == id && item.id_variant == id_variant)
                );
                if (!quantity || quantity <= 0) quantity = 1;
                this.cart.unshift({ id, quantity, numberInStock, id_variant });
            }

            if (this.authS.isAuth) {
                this.httpClient
                    .post(
                        this.queriesS.URL.cart,
                        { id, quantity, id_variant, numberInStock },
                        { params: { mode: 'one' } }
                    )
                    .subscribe((data: any) => {
                        this.cart = data;
                    });
            } else if (this.ssrS.isBrowser) {
                localStorage.setItem('cart', JSON.stringify(this.cart));
            }
        }
    }

    deleteFromCart(id: number) {
        if (this.ssrS.isBrowser) {
            if (!this.authS.isAuth) this.cart = this.getCartFromLocalStorage();
            this.cart = this.cart.filter((item) => item.id !== id);
            if (this.authS.isAuth) {
                this.httpClient
                    .delete(this.queriesS.URL.cart, { params: { id: id + '' } })
                    .subscribe((data: any) => {
                        this.cart = data;
                        localStorage.setItem('cart', JSON.stringify(this.cart));
                    });
            } else {
                localStorage.setItem('cart', JSON.stringify(this.cart));
            }
        }
    }

    changeCartItemQuantity(id: number, quantity: number) {
        if (this.ssrS.isBrowser) {
            if (!this.authS.isAuth) this.cart = this.getCartFromLocalStorage();
            this.cart.forEach((item) => {
                if (item.id === id) item.quantity = quantity;
            });
            if (this.authS.isAuth) {
                this.httpClient
                    .put(this.queriesS.URL.cart, { id, quantity })
                    .subscribe((data: any) => {
                        this.cart = data;
                        localStorage.setItem('cart', JSON.stringify(this.cart));
                    });
            } else {
                localStorage.setItem('cart', JSON.stringify(this.cart));
            }
        }
    }

    postLocalCartAfterRegister() {
        return new Promise((resolve: any) => {
            if (this.ssrS.isBrowser) {
                this.cart = this.getCartFromLocalStorage();
                this.httpClient
                    .post(this.queriesS.URL.cart, this.cart, {
                        params: { mode: 'all' },
                    })
                    .subscribe({
                        complete: () => resolve(),
                    });
            }
        });
    }
}

type cartItem = { id: number; quantity: number; numberInStock: number };
