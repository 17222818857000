import { Injectable } from '@angular/core';
import standartDictionary from './dictionary.json';
import authDictionary from './authDictionary.json';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { SsrService } from '../ssr.service';
import { parseUrl } from 'src/app/_utils/urlParser';

// tsconfig.json / compilerOptions

// "resolveJsonModule": true,
// "esModuleInterop": true,
// "allowSyntheticDefaultImports": true

// после перезапустить vscode

let dictionary: any = { ...standartDictionary, ...authDictionary }; // для типа данных // словари добавлять сдесь
export type dictionaryKey = keyof typeof dictionary;

(function testDictionariesToDublicateKeys() {
    // тест на повторы
    const dictionariesArr = [standartDictionary, authDictionary]; // словари добавлять сдесь

    const keys: any = {};
    for (let dict of dictionariesArr) {
        for (let key in dict) {
            if (key in keys) throw `dublicate key \"${key}\" in LanguageService`;
            else keys[key] = true;
        }
    }
})();

export type translateArgs = { key: dictionaryKey; replaceValues?: any[] };
export type Langs = 'ro' | 'ru'

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    readonly dictionary = dictionary;
    readonly languages: Langs[] = ['ro', 'ru']; // меняя [0], поменять и в app-routing.module

    activeLang: Langs = 'ru';

    onChangeLang: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(private location: Location, private ssrS: SsrService) {
        const urlData = parseUrl(this.location.path());
        this.selectLang(urlData.pathValues[0]);
    }

    selectLang(lang: any) {
        if (this.languages.indexOf(lang) === -1) {
            console.warn(`language "${lang}" not in languages`);
            if (this.ssrS.isBrowser)
                lang = localStorage.getItem('defaultLang') || 'ru';
            if (this.languages.indexOf(lang) === -1) lang = this.languages[0];
        }
        if (this.activeLang === lang) return;
        this.activeLang = lang;
        if (this.ssrS.isBrowser) localStorage.setItem('defaultLang', lang);
        if (this.onChangeLang) this.onChangeLang.next(lang);
        else this.onChangeLang = new BehaviorSubject(lang);
    }

    translate(key: dictionaryKey, replaceValues: any = []) {
        if (!this.dictionary[key]) {
            console.warn(`key "${String(key)}" not in dictionary`);
            return 'TRANSLATE_KEY_ERR';
        }
        if (!this.dictionary[key][this.activeLang]) {
            console.warn(
                `value by key "${String(key)}" and lang "${this.activeLang
                }" not in dictionary`
            );
            return 'TRANSLATE_VALUE_ERR';
        }
        return this.stringFormat(
            this.dictionary[key][this.activeLang],
            replaceValues
        );
    }

    private stringFormat(str: string, values: any[]) {
        values.forEach((value, idx) => {
            str = str.replace(RegExp(`%${idx}`, 'g'), value);
        });
        return str;
    }
}
