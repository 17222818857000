<div class="wrapper">
    <div class="header">
        <div class="header-content">
            <div class="bread">
                {{ langS.translate("home") }} / {{ langS.translate("myCart") }}
            </div>
            <h2 class="title">{{ langS.translate("myCart") }}</h2>
        </div>
    </div>

    <div class="content">
        <div *ngIf="cart && cart.length > 0" class="cart-table">
            <div class="row table-header">
                <div class="cell image">Item</div>
                <div class="cell name">Description</div>
                <div class="cell quantity">Quantity</div>
                <div class="cell price">Unit price</div>
                <div class="cell price">Subtotal</div>
                <div class="cell delete"></div>
            </div>
            <div *ngFor="let product of cart" class="row">
                <div class="cell image">
                    <figure>
                        <img [src]="settings.getImgSrc(product.mainImage)" alt="" />
                    </figure>
                </div>
                <div class="cell name">{{ product.title[langS.activeLang] }} {{ getVariantName(product) }}</div>
                <div class="cell quantity">
                    <div class="count-block">
                        <button (click)="setProductCount(product, 'minus')" class="btn-light">
                            <i class="fa-solid fa-minus"></i>
                        </button>
                        <div class="count">{{ product.quantity }}</div>
                        <button (click)="setProductCount(product, 'plus')" class="btn-light">
                            <i class="fa-solid fa-plus"></i>
                        </button>
                    </div>
                </div>
                <div class="cell price">{{ getItemPrice(product) }} MDL</div>
                <div class="cell price">
                    {{ getItemPrice(product) * product.quantity }} MDL
                </div>
                <div (click)="deleteFromCart(product)" class="cell delete">
                    <i class="fa-solid fa-times"></i>
                </div>
            </div>

            <div class="row total-row">
                <div class="cell">{{ langS.translate("total") }}:</div>
                <div class="cell price">{{ calcTotal() }} MDL</div>
            </div>
        </div>

        <div *ngIf="cart && cart.length == 0" class="empty">
            <i class="fa-solid fa-cart-shopping"></i>
            <span class="empty-text">{{ langS.translate("cartIsEmpty") }}</span>
        </div>
    </div>

    <button *ngIf="cart.length > 0" (click)="showConfirmOrder()" class="btn continue-btn">
        {{ langS.translate("continueOrder") }}
    </button>
</div>

<ys-modal *ngIf="isShowConfirmOrder && authS.user" class="order-modal" [header]="langS.translate('finish')"
    [(visible)]="isShowConfirmOrder">
    <div class="content">
        <div *ngIf="cart && cart.length > 0" class="cart-table">
            <div class="row table-header">
                <div class="cell name">Description</div>
                <div class="cell quantity">Quantity</div>
                <div class="cell price">Unit price</div>
                <div class="cell price">Subtotal</div>
            </div>
            <div *ngFor="let product of cart" class="row">
                <div class="cell name">{{ product.title[langS.activeLang] }}</div>
                <div class="cell quantity">
                    <div class="count">{{ product.quantity }}</div>
                </div>
                <div class="cell price">{{ getItemPrice(product) }} MDL</div>
                <div class="cell price">
                    {{ getItemPrice(product) * product.quantity }} MDL
                </div>
            </div>

            <div class="row total-row">
                <div class="cell">{{ langS.translate("total") }}:</div>
                <div class="cell price">{{ calcTotal() }} MDL</div>
            </div>
        </div>

        <div class="user-data">
            <div class="input-field">
                <label>{{ langS.translate("firstName") }}</label>
                <input disabled [ngModel]="orderForm.name" type="text" />
            </div>
            <div class="input-field">
                <label>{{ langS.translate("phone") }}</label>
                <input disabled [ngModel]="orderForm.phone" type="text" />
            </div>
            <div class="input-field">
                <label>Email</label>
                <input disabled [ngModel]="orderForm.email" type="text" />
            </div>
            <div class="input-field">
                <label class="label">{{ langS.translate("address") }}</label>
                <input [(ngModel)]="orderForm.address" type="text" />
            </div>
            <div class="input-field">
                <label class="label">{{ langS.translate("description") }}</label>
                <textarea [(ngModel)]="orderForm.description" type="text"></textarea>
            </div>
        </div>

        <button (click)="confirmOrder()" class="btn">
            {{ langS.translate("sendOrder") }}
        </button>
    </div>
</ys-modal>