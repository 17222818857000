import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-pagination-2',
    templateUrl: './pagination2.component.html',
    styleUrls: ['./pagination2.component.scss']
})
export class Pagination2Component implements OnInit {
    _selectedPage = 1;
    @Input() set selectedPage(value: number) {
        if (this._selectedPage === value) return;
        this._selectedPage = value;
        this.updatePagination();
    };
    @Output() selectedPageChange = new EventEmitter<number>();
    get selectedPage() {
        return this._selectedPage;
    }
    _pagesQuantity = 1;
    get pagesQuantity() {
        return this._pagesQuantity
    }
    @Input() set pagesQuantity(value: number) {
        this._pagesQuantity = value;
        this._selectedPage = Math.min(this._pagesQuantity, this._selectedPage);
        this.updatePagination();
    };
    pages: number[];

    ngOnInit() {
        this.updatePagination();
    }

    setPage(page: number) {
        if (page > this.pagesQuantity || page < 1) return;
        this.selectedPage = page;
        this.selectedPageChange.emit(page);
    }

    updatePagination() {
        this.pages = [];
        if (this.selectedPage > 2) this.pages.push(this.selectedPage - 2);
        if (this.selectedPage > 1) this.pages.push(this.selectedPage - 1);
        this.pages.push(this.selectedPage);
        if (this.selectedPage < this.pagesQuantity) this.pages.push(this.selectedPage + 1);
        if (this.selectedPage < this.pagesQuantity - 1) this.pages.push(this.selectedPage + 2);
    }
}
