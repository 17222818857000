import { BrowserModule } from '@angular/platform-browser';
// import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { YsModalComponent } from './ys-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [YsModalComponent],
  imports: [BrowserModule, FormsModule],
  exports: [YsModalComponent],
})
export class YsModalModule {}
