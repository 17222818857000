import { SsrService } from './ssr.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class YsToastService {
    private toastContainer: any;

    constructor(private ssr: SsrService) {
        if (this.ssr.isBrowser) {
            this.toastContainer = document.createElement('div');
            this.toastContainer.id = 'ys-toasts';
            document.querySelector('body').appendChild(this.toastContainer);
        }
    }

    private getToast() {
        if (this.ssr.isBrowser) {
            let toast = document.createElement('div');
            toast.className = 'ys-toast';
            toast.innerHTML = `
    <i class="toast-icon"></i>
    <div class="toast-content">
        <div class="header">
        <i class="close-toast-btn pi pi-times"></i>
            <span class="toast-title">Title Toast</span>
            <div class="close-toast-btn">
                <i class="flaticon-cancel"></i>
            </div>
        </div>
        <span class="toast-message">
            Text message for toast
        </span>
    </div>
          `;
            return toast;
        }

        return '';
    }

    showMessage(
        config = {
            title: '',
            text: 'Text',
            typeToast: 'success',
            duration: 600,
        }
    ) {
        let typesToast = ['success', 'error', 'warn'];
        let toast = this.getToast();

        // @ts-ignore
        toast.querySelector('.toast-title').innerHTML = config.title;

        // @ts-ignore
        toast.querySelector('.toast-message').innerHTML = config.text;

        if (this.toastContainer) {
            this.toastContainer.style.zIndex = '100000';

            this.toastContainer.appendChild(toast);
        }

        if (typesToast.includes(config.typeToast)) {
            let icon =
                config.typeToast == 'success'
                    ? 'flaticon-tick'
                    : config.typeToast == 'error'
                        ? 'flaticon-error'
                        : 'flaticon-warning-sign';

            // @ts-ignore
            toast.classList.add('toast-' + config.typeToast);

            // @ts-ignore
            toast.querySelector('.toast-icon').classList.add(icon);
            // @ts-ignore
        } else toast.classList.add('toast-warn');

        // @ts-ignore
        toast.addEventListener('mouseover', () => {
            // @ts-ignore
            toast.dataset.isHover = true;
        });

        // @ts-ignore
        let closeBtn = toast.querySelector('.close-toast-btn');
        if (closeBtn) closeBtn.addEventListener('click', () => closeToast(true));

        if (!config.duration) config.duration = 2000;

        setTimeout(() => closeToast(), config.duration);

        const closeToast = (hardcore = false) => {
            // @ts-ignore
            if (toast.dataset.isHover && !hardcore) return;

            // @ts-ignore
            toast.style.animationName = 'toast-close';
            setTimeout(() => {
                // @ts-ignore
                if (toast && toast.parentElement)
                    // @ts-ignore
                    toast.parentElement.removeChild(toast);
                if (this.toastContainer.querySelectorAll('.ys-toast').length <= 0)
                    this.toastContainer.style.zIndex = '-1';
                // toast.querySelector('.toast-title').innerHTML = '';
                // toast.querySelector('.toast-message').innerHTML = '';
            }, 600);
        };
    }
}
