import { FavoritesService } from './_services/favorites.service';
import { CartService } from './_services/cart.service';
import { AuthService } from 'src/app/_services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { SettingsService } from './_services/settings.service';
import { LanguageService } from 'src/app/_services/internationality/language.service';
import { ProductsCategoriesService } from './_services/products-categories.service';
import { QueriesService } from './_services/queries.service';
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { debounce, filter } from 'rxjs/operators';
import { interval } from 'rxjs';
import { SsrService } from './_services/ssr.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private query: QueriesService,
        public categoryService: ProductsCategoriesService,
        public langS: LanguageService,
        public cartS: CartService,
        public settings: SettingsService,
        private router: Router,
        public auth: AuthService,
        public favoriteS: FavoritesService,
        private cdr: ChangeDetectorRef,
        private ssrS: SsrService
    ) {
        if (this.ssrS.isBrowser) {
            let storageResult = localStorage.getItem('toggleCategories');
            this.isShowCategories = storageResult ? Boolean(+storageResult) : false;
        }
    }

    isShowMobileMenu = false;
    isShowCategories = false;

    searchText = '';
    searchProductSubs = new EventEmitter<any>();
    searchedProducts: any = null;

    ngOnInit() {
        (async () => {
            await this.auth.checkTokenAndGetUser(false);
            // this.favoritesS.updateFavorites();
            this.cartS.updateCart();
        })();

        var ua = navigator.userAgent;

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe(() => this.isShowCategories = false);
        }

        this.favoriteS.updateFavorites();

        this.initSearch();
        this.settings.get_contacts();
    }

    toggleCategories(e: Event) {
        if (e) e.stopPropagation();

        this.isShowCategories = !this.isShowCategories;
        if (this.ssrS.isBrowser) {
            localStorage.setItem(
                'toggleCategories',
                Number(this.isShowCategories) + ''
            );
        }
    }

    getSubPosition(event: Element) {

        let parent = event.parentElement;
        let positionClass = 'sub-left';
        let docWidth = this.document.documentElement.offsetWidth;

        if (parent?.offsetLeft && parent?.offsetLeft < docWidth / 4)
            positionClass = 'sub-left';
        else if (
            parent?.offsetLeft &&
            parent?.offsetLeft > docWidth / 4 &&
            parent?.offsetLeft < docWidth / 1.25
        )
            positionClass = 'sub-center';
        else if (parent?.offsetLeft && parent?.offsetLeft > docWidth / 2)
            positionClass = 'sub-right';
        return positionClass;

    }

    initSearch() {
        let a = this.searchProductSubs.pipe(debounce(() => interval(500)));
        a.subscribe((event: any) => {
            this.searchProducts(event.target.value);
        });
    }

    searchProducts(text: any) {
        if (text && text !== '')
            this.query.search_products(text).subscribe((data: any) => {
                this.searchedProducts = data;
                this.searchText = text;
            });
        else {
            this.searchText = '';
            this.searchedProducts = null;
        }
    }

    selectSearchProduct(product: any) {
        this.searchedProducts = null;
        this.router.navigate(['/product', product.id]);
    }
}
