<form [formGroup]="loginForm" novalidate (submit)="login()" class="form">
  <div class="input-field" [class.error]="false">
    <label>E-mail</label>
    <input
      (focus)="serverError = ''"
      type="text"
      formControlName="email"
      placeholder="email"
    />
    <app-validation-error-show
      [control]="loginForm.controls.email"
    ></app-validation-error-show>
  </div>
  <div class="input-field" [class.error]="false">
    <label>{{ langS.translate("password") }}</label>
    <input
      type="password"
      (focus)="serverError = ''"
      formControlName="password"
      placeholder="password"
    />
    <app-validation-error-show
      [control]="loginForm.controls.password"
    ></app-validation-error-show>
  </div>

  <div class="server-error">
    <span *ngIf="serverError">{{ langS.translate(serverError) }}</span>
  </div>
  <button class="btn">{{ langS.translate("login") }}</button>

  <div class="nav">
    <span (click)="settingsS.showRegister()" class="link register">{{
      langS.translate("register")
    }}</span>
    <span (click)="settingsS.goToForgot()" class="link forgot">{{
      langS.translate("forgotPass")
    }}</span>
  </div>
</form>
