import { LanguageService } from 'src/app/_services/internationality/language.service';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-validation-error-show',
  templateUrl: './validation-error-show.component.html',
  styleUrls: ['./validation-error-show.component.scss'],
})
export class ValidationErrorShowComponent {
  @Input() control: FormControl | FormGroup | AbstractControl;
  @Input() showFirstOnly = true;
  @Input() set customErrorsMessages(customMessages: {
    [errorType: string]: (errorValue: any) => string;
  }) {
    this.messages = { ...this.standartMessages, ...customMessages };
  }

  objectKeys = Object.keys;

  constructor(private langS: LanguageService) {}

  standartMessages: { [errorType: string]: (errorValue?: any) => string } = {
    min: (errorValue: { min: 3; actual: 2 }) => {
      // return (
      //   'The value must be>=' +
      //   errorValue.min +
      //   '(' +
      //   errorValue.actual +
      //   '<' +
      //   errorValue.min +
      //   ').'
      // );
      return this.langS.translate('minNumberError', [
        errorValue.min,
        errorValue.actual,
      ]);
    },
    max: (errorValue: { max: 15; actual: 16 }) => {
      // return (
      //   'The value must be>=' +
      //   errorValue.max +
      //   '(' +
      //   errorValue.actual +
      //   '<' +
      //   errorValue.max +
      //   ').'
      // );
      return this.langS.translate('maxNumberError', [
        errorValue.max,
        errorValue.actual,
      ]);
    },
    required: () => {
      // return 'Required field';
      return this.langS.translate('requiredError');
    },
    email: () => {
      // return 'Invalid email.';
      return this.langS.translate('invalidEmailError');
    },
    minlength: (errorValue: {
      requiredLength: number;
      actualLength: number;
    }) => {
      // return (
      //   'Minimum length ' +
      //   errorValue.requiredLength +
      //   ' characters' +
      //   '(entered ' +
      //   errorValue.actualLength +
      //   ').'
      // );
      return this.langS.translate('minLengthError', [
        errorValue.requiredLength,
        errorValue.actualLength,
      ]);
    },
    maxlength: (errorValue: {
      requiredLength: number;
      actualLength: number;
    }) => {
      // return (
      //   'Maximum length ' +
      //   errorValue.requiredLength +
      //   ' characters (entered ' +
      //   errorValue.actualLength +
      //   ').'
      // );
      return this.langS.translate('maxLengthError', [
        errorValue.requiredLength,
        errorValue.actualLength,
      ]);
    },
    pattern: (errorValue: {
      requiredPattern: string | RegExp;
      actualValue: string;
    }) => {
      // actualValue - текущее значение
      // if (requiredPattern === ...) return this.langS.translate("...");
      // else if (...) return this.langS.translate("...");
      // return (
      //   'The value must match the regular expression  ' +
      //   errorValue.requiredPattern +
      //   '.'
      // );
      return this.langS.translate('patternError', [errorValue.requiredPattern]);
    },

    phoneError: () => {
      // return 'The phone can only contain numbers, spaces and dashes.';
      return this.langS.translate('invalidPhoneError');
    },
    passwordConfirmError: () => {
      // return 'Passwords must match.';
      return this.langS.translate('passwordConfirmError');
    },
    trimError: () => {
      // return 'The value must not start or end with whitespace.';
      return this.langS.translate('trimError');
    },
    emailIsBusy: () => {
      // return 'Email is busy.';
      return this.langS.translate('emailIsBusyError');
    },
    unknownError: () => {
      // return 'Unknown error. The administrator has been notified. Sorry.';
      return this.langS.translate('unknZownError');
    },
    invalidEmailOrPassword: () => {
      // return 'Username or password not valid';
      return this.langS.translate('invalidEmailOrPasswordError');
    },
    userIsBlock: () => {
      // return 'the user is blocked';
      return this.langS.translate('userIsBlockError');
    },
    userNotIsActive: () => {
      // return 'User is not active. To activate your account, follow the link sent to your email. ';
      return this.langS.translate('userNotIsActiveError');
    },
    messagesLimit: () => {
      // return 'Sorry, but too many emails have been sent from your account. Try to repeat the action tomorrow.';
      return this.langS.translate('messagesLimitError');
    },
    invalidPassword: () => {
      // return 'Incorrect password.';
      return this.langS.translate('invalidPasswordError');
    },
  };

  messages = { ...this.standartMessages };

  getErrorText(errorName: string) {
    if (!(errorName in this.messages)) return this.messages.unknownError();
    else return this.messages[errorName](this.control.errors[errorName]);
  }
}
