import { SettingsService } from 'src/app/_services/settings.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { deleteCookie, getCookie, setCookie } from '../_utils/cookieUtils';
import { SsrService } from './ssr.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    user: User = null;
    onUserDataChanged = new EventEmitter();

    get isAuth() {
        return !!this.user;
    }

    resources = {
        login: '/client/login',
        logout: '/client/logout',
        checkToken: '/client/check-token',
        registerStep1: '/client/register-step-1',
        registerStep2: '/client/register-step-2',
        forgotPasswordStep1: '/client/forgot-password-step-1',
        forgotPasswordStep2: '/client/forgot-password-step-2',
        forgotPasswordStep3: '/client/forgot-password-step-3',
        changePassword: '/client/change-password',
    };

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private ssrS: SsrService,
        private _settings: SettingsService
    ) { }

    login(email: string, password: string, remember: boolean = true) {
        if (this.ssrS.isBrowser) {
            return new Promise<void>((resolve, reject) => {
                this.httpClient
                    .post(this.resources.login, { email, password })
                    .subscribe(
                        (data: any) => {
                            this.user = data.user;
                            if (this.ssrS.isBrowser) {
                                if (remember) {
                                    localStorage.setItem('token', data.token);
                                    deleteCookie('token');
                                } else {
                                    localStorage.removeItem('token');
                                    setCookie('token', data.token);
                                }
                            }
                            this.onUserDataChanged.emit();
                            resolve();
                        },
                        (error) => {
                            reject(error.error);
                        }
                    );
            });
        }
        return false;
    }

    logout() {
        if (this.ssrS.isBrowser) {
            this.httpClient.get(this.resources.logout).subscribe(() => { });
            this.user = null;
            if (this.ssrS.isBrowser) {
                localStorage.removeItem('token');
                deleteCookie('token');
            }
            // this._settings.logout = false;
            this.router.navigate(['/']);
            this.onUserDataChanged.emit();
        }
    }

    register(body: any) {
        return new Promise<void>((resolve, reject) => {
            this.httpClient.post(this.resources.registerStep1, body).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (error) => {
                    reject(error.error);
                }
            );
        });
    }

    getToken() {
        return this.ssrS.isBrowser
            ? getCookie('token') || localStorage.getItem('token')
            : '';
    }

    startChangeForgotPassword(email: string) {
        return this.httpClient.post(
            this.resources.forgotPasswordStep1,
            { email },
            { observe: 'response' }
        );
    }

    endChangeForgotPassword(password: string) {
        return this.httpClient.post(
            this.resources.forgotPasswordStep3,
            { password },
            {
                params: this.router.parseUrl(this.router.url).queryParams,
                observe: 'response',
            }
        );
    }

    changePassword(oldPassword: string, newPassword: string) {
        return new Promise<void>((resolve, reject) => {
            this.httpClient
                .post(this.resources.changePassword, { oldPassword, newPassword })
                .subscribe(
                    () => {
                        this.user = null;
                        this.onUserDataChanged.emit();
                        resolve();
                    },
                    (error) => {
                        reject(error.error);
                    }
                );
        });
    }

    checkTokenAndGetUser(blockPage = true) {
        return new Promise<boolean>((resolve) => {
            if (!this.getToken()) {
                if (blockPage) this._blockPage();
                return resolve(false);
            }
            this.httpClient.get(this.resources.checkToken).subscribe(
                (data: any) => {
                    this.user = data;
                    this.onUserDataChanged.emit();
                    resolve(true);
                },
                () => {
                    if (blockPage) this._blockPage();
                    resolve(false);
                }
            );
        });
    }

    private _blockPage() {
        this.user = null;
        if (this.ssrS.isBrowser) {
            localStorage.removeItem('token');
            deleteCookie('token');
        }
        this.onUserDataChanged.emit();
        this.router.navigateByUrl(`/home`);
    }

    loginFromGoogle(remember: boolean) {
        if (this.ssrS.isBrowser) {
            return new Promise<void>((resolve, reject) => {
                const onMessage = (event: MessageEvent) => {
                    if (event.origin === environment.url) {
                        const data = JSON.parse(event.data);
                        this.user = data.user;
                        if (this.ssrS.isBrowser) {
                            if (data.remember) {
                                localStorage.setItem('token', data.token);
                                deleteCookie('token');
                            } else {
                                localStorage.removeItem('token');
                                setCookie('token', data.token);
                            }
                        }
                        this.onUserDataChanged.emit();
                        resolve();
                    }
                };

                let width = Math.min(window.outerWidth, 500);
                let height = Math.min(window.outerHeight, 500);
                let authWindow = open(
                    environment.url + `/client/login_from_google_1?remember=${remember}`,
                    '',
                    `width=${width},height=${height}`
                );
                authWindow.moveTo(
                    (window.outerWidth - width) / 2,
                    (window.outerHeight - height) / 2
                );
                window.addEventListener('message', onMessage);

                const timer = setInterval(() => {
                    if (authWindow.closed) {
                        window.removeEventListener('message', onMessage);
                        clearInterval(timer);
                        reject();
                    }
                }, 100);
            });
        }
        return false;
    }

    loginFromFacebook(remember: boolean) {
        if (this.ssrS.isBrowser) {
            return new Promise<void>((resolve, reject) => {
                const onMessage = (event: MessageEvent) => {
                    if (event.origin === environment.url) {
                        const data = JSON.parse(event.data);
                        this.user = data.user;
                        if (this.ssrS.isBrowser) {
                            if (data.remember) {
                                localStorage.setItem('token', data.token);
                                deleteCookie('token');
                            } else {
                                localStorage.removeItem('token');
                                setCookie('token', data.token);
                            }
                        }
                        this.onUserDataChanged.emit();
                        resolve();
                    }
                };

                let width = Math.min(window.outerWidth, 500);
                let height = Math.min(window.outerHeight, 500);
                let authWindow = open(
                    environment.url +
                    `/client/login_from_facebook_1?remember=${remember}`,
                    '',
                    `width=${width},height=${height}`
                );
                authWindow.moveTo(
                    (window.outerWidth - width) / 2,
                    (window.outerHeight - height) / 2
                );
                window.addEventListener('message', onMessage);

                const timer = setInterval(() => {
                    if (authWindow.closed) {
                        window.removeEventListener('message', onMessage);
                        clearInterval(timer);
                        reject();
                    }
                }, 100);
            });
        }
        return false;
    }
}

export type User = {
    name: string;
    phone: string;
    token?: string;
    password?: string;
    email: string;
    favorites: number[];
    cart: { id: number; quantity: number }[];
};
