import { HttpClient } from '@angular/common/http';
import { EventEmitter, HostListener, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileObj, Img } from '../_models/common';
import { LanguageModel } from '../_models/language.model';
import { CutedRealProduct } from '../_models/productsAndCategories';
// import { AuthService } from './auth.service';
import {
    LanguageService,
    translateArgs,
} from './internationality/language.service';
import { QueriesService } from './queries.service';
import { SsrService } from './ssr.service';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    contacts: any = null;

    selectedSeo: Seo;

    isShowLogin = false;
    isShowRegister = false;
    sliderHeight = 700;
    logo = {
        'url': '',
        'alt': new LanguageModel
    };

    constructor(
        private _lng: LanguageService,
        private _query: QueriesService,
        private router: Router,
        // private authS: AuthService,
        private httpClient: HttpClient,
        private ssrS: SsrService,
        @Inject(DOCUMENT) private document: Document,
        private location: Location,
        public sanitizer: DomSanitizer,
        private queries: QueriesService,
    ) {
        this._query.get_common_data("settings").subscribe(async (data: any) => {
            this.sliderHeight = data['slider']['height'];
            this.logo['url'] = data['logo']['url']
            if (data['jivochat']['key']) {
                //this.document.head.insertAdjacentHTML("beforeend", "<script src=" + data['jivochat']['key'] + " async></script>");
                var jivoScript = document.createElement('script');
                jivoScript.setAttribute('src', data['jivochat']['key']);
                jivoScript.setAttribute('async', '');
                document.head.appendChild(jivoScript);
            }
        })
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                //this.clearSeo();
            }
        });

        this.get_contacts();
    }


    showRegister() {
        this.isShowLogin = false;
        this.isShowRegister = true;
    }

    showLogin() {
        this.isShowRegister = false;
        this.isShowLogin = true;
    }

    goToForgot() {
        this.isShowRegister = false;
        this.isShowLogin = false;
        this.router.navigate(['auth', 'forgot-password']);
    }

    get_contacts() {
        this._query.get_contacts().subscribe((contacts: any) => {
            this.contacts = contacts;
            this.contacts[0].map_link = this.sanitizer.bypassSecurityTrustHtml(
                this.contacts[0].map_link
            );
            this.contacts[1].map_link = this.sanitizer.bypassSecurityTrustHtml(
                this.contacts[1].map_link
            );
        });
    }

    getProductPriceTag(withSale: boolean = true, product: any, sale: number) {
        let price: any = '--';
        if (product) {
            price = product.price;

            if (withSale && sale > 0) {
                price = Math.ceil(price - (sale / 100) * price);
            }
        }

        return price;
    }

    getCommonData(param: string) {
        return this.httpClient.get(this._query.URL.commonData, {
            params: { param },
        });
    }

    getImgSrc(img: Img) {
        if (
            img.url &&
            !img.url.startsWith('/static') &&
            !img.url.startsWith('assets')
        )
            return img.url;
        return img.url ? environment.url + img.url : 'assets/images/noimage.png';
    }

    downloadFile(url: string) {
        // атрибут download не работает для другого домена
        fetch(environment.url + url).then(async (resp) => {
            const anchor = this.document.createElement('a');
            anchor.href = URL.createObjectURL(await resp.blob());
            anchor.download = url.split('/').pop();
            anchor.click();
        });
    }

    getShareLink(
        socialName: string,
        url = 'https://energen.md' + this.location.path()
    ) {
        if (socialName === 'facebook')
            return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`;
        else if (socialName === 'linkedin')
            return `https://www.linkedin.com/shareArticle?&url=${encodeURIComponent(
                url
            )}`;
        else if (socialName === 'twitter')
            return `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}
            &text=${encodeURIComponent(
                this.document.title
            )}&hashtags=${environment.url.replace(/\./g, '_')}`;
        else if (socialName === 'vk')
            return `https://vk.com/share.php?url=${encodeURIComponent(url)}`;
        else if (socialName === 'ok')
            return `https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=${encodeURIComponent(
                url
            )}`;
        else if (socialName === 'whatsapp')
            return this.sanitizer.bypassSecurityTrustUrl(
                `whatsapp://send?text=${encodeURIComponent(url)}`
            );

        return '';
        //     return this.sanitizer.bypassSecurityTrustUrl(`whatsapp://send?text=${encodeURIComponent(url)}`);
    }

    getProductPrice(
        product: CutedRealProduct,
        quantity = 1,
        withDiscount = true,
        asString = true
    ) {
        let price = product.price;

        if (
            withDiscount &&
            price &&
            product.discountValue > 0 &&
            product.discountDateStart &&
            product.discountDateEnd &&
            product.discountDateStart * 1000 < +new Date() &&
            product.discountDateEnd * 1000 > +new Date()
        ) {
            price = (price / 100) * (100 - product.discountValue);
        }

        if (!('userDiscountValue' in product)) {
            throw 'userDiscountValue field not exists';
        } else if (
            withDiscount &&
            product.userDiscountValue !== null &&
            product.userDiscountValue > 0
        ) {
            price = (price / 100) * (100 - product.userDiscountValue);
        }

        price = (Math.round(price * 100) / 100) * quantity;

        if (price && asString) return this.priceFormat(price);
        else return price;
    }

    priceFormat(price: number) {
        let newPrice = Math.round(price * 100) / 100 + '';
        const pointIdx = newPrice.indexOf('.');
        if (pointIdx == -1) return newPrice;
        else if (pointIdx == newPrice.length - 2) return newPrice + '0';
        else return newPrice.substring(0, pointIdx + 3); // возможна математическая погрешность
    }

    getTotalPrice(
        cartItems: {
            product: CutedRealProduct;
            quantity: number;
        }[],
        withDiscount: boolean
    ) {
        let sum = 0;
        for (let item of cartItems) {
            if (item.product.price)
                sum += this.getProductPrice(
                    item.product,
                    item.quantity,
                    withDiscount,
                    false
                ) as number;
            else return 0;
        }

        if (!sum) return 0; // так спокойней
        return this.priceFormat(sum);
    }

    applySinitizer(text: LanguageModel) {
        for (let lang of this._lng.languages)
            text[lang] = this.sanitizer.bypassSecurityTrustHtml(text[lang]) as any;
    }
}

class Seo {
    id: number;
    pageType: string;
    title: LanguageModel;
    description: LanguageModel;
    keywords: LanguageModel;
    noindex: boolean;
    nofollow: boolean;

    openGraph: {
        title: LanguageModel;
        description: LanguageModel;
        image: string;
        imageType: string;
        imageWidth: number;
        imageHeight: number;
    };
}

type breadcrumbItem = {
    title: LanguageModel;
    url: string;
};
