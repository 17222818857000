import { LanguageService } from 'src/app/_services/internationality/language.service';
import { AuthService } from 'src/app/_services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  email = '';
  isValidate = false;
  respMsg = '';

  isWait = false;

  constructor(private auth: AuthService, public langS: LanguageService) {}

  ngOnInit(): void {}

  startReset() {
    if (this.validateEmail(this.email)) {
      this.isWait = true;
      this.auth.startChangeForgotPassword(this.email).subscribe(
        (data: any) => {
          let msg = JSON.parse(data.headers.get('Show-Message'));
          this.respMsg = msg.description.key;
          this.isWait = false;
        },
        (err) => {
          let msg = JSON.parse(err.headers.get('Show-Message'));
          this.respMsg = msg.description.key;
          this.isWait = false;
        }
      );
    }
  }

  validateEmail(email: any) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
