import { LanguageService } from './../../_services/internationality/language.service';
import { SettingsService } from './../../_services/settings.service';
import { Component, OnInit } from '@angular/core';
import { CartService } from '../../_services/cart.service';
import { YsToastService } from '../../_services/ys-toast.service';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
    images = [
        'https://uh8yh30l48rpize52xh0q1o6i-wpengine.netdna-ssl.com/wp-content/uploads/2013/09/26924_6aed022f4d624c86_b.jpg',
        'https://www.performa.de/mediatypes/gallery/perf_08_021.jpg',
        'https://www.luxxu.net/blog/wp-content/uploads/2020/05/vertigo-long-850x410.jpg',
    ];

    data: any;

    constructor(
        public settingsS: SettingsService,
        public langS: LanguageService,
        public cartS: CartService,
        private ysToast: YsToastService
    ) { }

    ngOnInit(): void {
        this.settingsS.getCommonData('homePage').subscribe((data: any) => {
            this.data = data;
        });
    }

    // getProductPrice(product: any, withSale: boolean = true) {
    //     let price: any = '--';
    //     if (product.variants && product.variants.length > 0) {
    //         price = product.variants[0].price;

    //         if (withSale && product.sale > 0) {
    //             price = price - (product.sale / 100) * price;
    //         }
    //     }

    //     return price;
    // }

    addToCart(event: Event, product: any) {
        event.stopPropagation();
        this.cartS.addToCart(
            product.id,
            product.variants[0].id,
            1,
            product.variants[0].numberInStock,
        );

        this.ysToast.showMessage({
            title: this.langS.translate('success'),
            text: this.langS.translate('isAddedToCart'),
            typeToast: 'success',
            duration: 2000,
        });
    }
}
