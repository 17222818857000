import { LanguageService } from './../../_services/internationality/language.service';
import { QueriesService } from './../../_services/queries.service';
import { SettingsService } from './../../_services/settings.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-aboutus-page',
    templateUrl: './aboutus-page.component.html',
    styleUrls: ['./aboutus-page.component.scss'],
})
export class AboutUsPageComponent implements OnInit {
    title = '';
    constructor(
        public settings: SettingsService,
        private _query: QueriesService,
        public langS: LanguageService,
        private route: ActivatedRoute,
        public router: Router,
    ) { }

    blocks: any[] = [];

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params.page == 'about-us')
                this._query.get_about_blocks().subscribe((data: any) => {
                    this.blocks = data;
                    this.title = 'aboutUs';
                });
            if (params.page == 'terms-and-conditions')
                this._query.get_terms_blocks().subscribe((data: any) => {
                    this.blocks = data;
                    this.title = 'termNcon';
                });

            if (params.page == 'warranty')
                this._query.get_warranty_blocks().subscribe((data: any) => {
                    this.blocks = data;
                    this.title = 'warranty';
                });
            if (params.page == 'privacy-policy')
                this._query.get_policy_blocks().subscribe((data: any) => {
                    this.blocks = data;
                    this.title = 'privacy-policy';
                });
            if (params.page == 'payment-ways')
                this._query.get_pay_blocks().subscribe((data: any) => {
                    this.blocks = data;
                    this.title = 'payment-ways';
                });
            if (params.page == 'keep-and-usage-guide')
                this._query.get_usageNkeep_guide_blocks().subscribe((data: any) => {
                    this.blocks = data;
                    this.title = 'keep-and-usage-guide';
                });
            if (params.page == 'transport')
                this._query.get_transport_blocks().subscribe((data: any) => {
                    this.blocks = data;
                    this.title = 'transport';
                });
        }, () => {
            this.router.navigateByUrl(`/${this.langS.activeLang}`);
        })
    }
}
