<section *ngIf="slider.length > 0">
    <div class="slider" [style.height]="_settings.sliderHeight+ 'px' " (mouseenter)="stopInterval()"
        (mouseleave)="startInterval()">
        <ng-container *ngFor="let item of slider">
            <a class="slider__item" [style.height]="_settings.sliderHeight+ 'px' " #slide
                [style.backgroundImage]="'url(' + _settings.getImgSrc(item.image) + ')'"
                [href]="item.url ? item.url : '#'" target="_blank" rel="noopener nofollow" aria-label="slide">
                <!-- <video [src]="_settings.getFileSrc(item.video)" [muted]="true" autoplay loop
                    *ngIf="item.isVideoSlide"></video> -->
                <div class="slider__item--content">
                    <h2 class="slider__item--caption" *ngIf="item.title[_lng.activeLang]">
                        {{ item.title[_lng.activeLang] }}
                    </h2>
                    <p class="slider__item--text" [innerHTML]="item.text[_lng.activeLang]"></p>
                </div>
            </a>
        </ng-container>

        <div class="wrapper slider__btns" *ngIf="slider.length > 1">
            <button id="back" class="slider__btn btn _icon flaticon-left-arrow" (click)="plusDivs(-1)"
                aria-label="prev">
                <i class="pi pi-angle-left"></i>
            </button>
            <button id="next" class="slider__btn btn _icon flaticon-next" (click)="plusDivs(+1)" aria-label="next">
                <i class="pi pi-angle-right"></i>
            </button>
            <div class="slider-dots">
                <span [ngClass]="idx + 1 == slideIndex ? 'dot_active' : 'dot'"
                    *ngFor="let item of slider; let idx = index"></span>
            </div>
        </div>
    </div>
</section>