<form
  [formGroup]="regForm"
  novalidate
  (submit)="confirmRegister()"
  class="form"
>
  <div class="input-field">
    <label>
      {{ langS.translate("lastName") }}/{{ langS.translate("firstName") }}
    </label>
    <input
      type="text"
      (focus)="serverError = ''"
      formControlName="name"
      [placeholder]="
        langS.translate('lastName') + '/' + langS.translate('firstName')
      "
    />
    <app-validation-error-show
      [control]="regForm.controls.name"
    ></app-validation-error-show>
  </div>
  <div class="input-field">
    <label>Email</label>
    <input
      type="email"
      (focus)="serverError = ''"
      formControlName="email"
      placeholder="Email"
    />
    <app-validation-error-show
      [control]="regForm.controls.email"
    ></app-validation-error-show>
    <!-- <div class="error__item">Please enter an email address.</div> -->
  </div>
  <div class="input-field">
    <label>{{ langS.translate("phone") }}</label>
    <input
      type="text"
      (focus)="serverError = ''"
      formControlName="phone"
      [placeholder]="langS.translate('phone')"
    />
    <app-validation-error-show
      [control]="regForm.controls.phone"
    ></app-validation-error-show>
    <!-- <div class="error__item">Please enter an email address.</div> -->
  </div>
  <div class="input-field">
    <label>{{ langS.translate("password") }}</label>
    <input
      type="password"
      (focus)="serverError = ''"
      formControlName="password"
      autocomplete="new-password"
      [placeholder]="langS.translate('password')"
    />
    <app-validation-error-show
      [control]="regForm.controls.password"
    ></app-validation-error-show>
  </div>
  <div class="input-field">
    <label>{{ langS.translate("confirmPass") }}</label>
    <input
      type="password"
      (focus)="serverError = ''"
      formControlName="repeatPassword"
      [placeholder]="langS.translate('confirmPass')"
    />
    <app-validation-error-show
      [control]="regForm.controls.repeatPassword"
    ></app-validation-error-show>
  </div>
  <div class="wrapper">
    <label for="check" class="form__checkbox">
      <input
        type="checkbox"
        formControlName="acceptTerms"
        checked="checked"
        id="check"
      />
      <span class="text checkmark"
        >{{ langS.translate("confOfReg") }}
        <span class="link">{{ langS.translate("privacyPolicy") }}</span>
      </span>
    </label>
  </div>
  <app-validation-error-show
    [control]="regForm.controls.acceptTerms"
  ></app-validation-error-show>

  <div class="server-error">
    <span *ngIf="serverError">{{ langS.translate(serverError) }}</span>
  </div>
  <button
    [disabled]="regForm.invalid || regForm.disabled"
    class="btn form__btn"
  >
    {{ langS.translate("register") }}
  </button>

  <div class="nav">
    <span
      >{{ langS.translate("alreadyRegistered") }}
      <span class="link" (click)="settingsS.showLogin()">{{
        langS.translate("login")
      }}</span>
    </span>
  </div>
</form>
