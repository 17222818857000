import { NONE_TYPE } from '@angular/compiler';
import {
    Component,
    Input,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { timeInterval } from 'rxjs/operators';
import { LanguageService } from 'src/app/_services/internationality/language.service';
import { SettingsService } from 'src/app/_services/settings.service';
import { SsrService } from 'src/app/_services/ssr.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
    slideIndex = 1;
    @Input() slider: any[] = [];
    autoSlide: any = null;

    @ViewChildren('slide') slide: QueryList<any>;
    constructor(
        public _settings: SettingsService,
        public _lng: LanguageService,
        public _sanitizer: DomSanitizer,
        private ssrS: SsrService
    ) {
        if (ssrS.isBrowser) this.startInterval();
    }

    ngAfterViewInit() {
        this.slide.changes.subscribe(() => {
            if (this.slide.toArray().length > 0) {
                this.showDivs(this.slideIndex);
            }
        });

        if (this.slide.toArray().length > 0) {
            this.showDivs(this.slideIndex);
        }
    }
    stopInterval() {
        if (this.autoSlide) clearInterval(this.autoSlide);
    }
    startInterval() {
        this.autoSlide = setInterval(() => {
            if (this.slide.toArray().length > 0) {
                this.plusDivs(1);
            }
        }, 7000);
    }
    plusDivs(n: any) {
        this.showDivs((this.slideIndex += n));
    }

    showDivs(n: any) {
        if (n > this.slide.length) {
            this.slideIndex = 1;
        }
        if (n < 1) {
            this.slideIndex = this.slide.length;
        }
        this.slide.forEach((child) => {
            child.nativeElement.style.display = 'none';
        });
        this.slide.toArray()[this.slideIndex - 1].nativeElement.style.display =
            'flex';
    }

    ngOnDestruction() {
        clearInterval(this.autoSlide);
    }
}
