import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class QueriesService {
    URL = {
        home: '/client/home-page',
        contacts: '/client/contacts-page',
        about: '/client/about',
        order: '/client/order',
        password: '/client/change-password',
        user: '/client/user',
        productsCategory: '/client/products-category',
        product: '/client/product',
        favorites: '/client/favorites',
        cart: '/client/cart',
        commonData: '/client/common-data',
        seo: '/client/seo',
        about_block: '/client/about_block',
        terms_block: '/client/terms_n_conditions',
        warranty_block: '/client/warranty',
        policy_block: '/client/policy',
        pay_block: '/client/payment',
        guide_block: '/client/usageNkeep-guide',
        transport_block: '/client/transport'
    };

    constructor(private http: HttpClient) { }

    get_home() {
        return this.http.get(environment.url + this.URL.home);
    }

    get_contacts() {
        return this.http.get(
            environment.url + this.URL.commonData + '?param=contacts'
        );
    }

    get_warranty_blocks() {
        return this.http.get(
            environment.url + this.URL.warranty_block
        );
    }

    get_policy_blocks() {
        return this.http.get(
            environment.url + this.URL.policy_block
        );
    }

    get_pay_blocks() {
        return this.http.get(
            environment.url + this.URL.pay_block
        );
    }

    get_usageNkeep_guide_blocks() {
        return this.http.get(
            environment.url + this.URL.guide_block
        );
    }

    get_transport_blocks() {
        return this.http.get(
            environment.url + this.URL.transport_block
        );
    }

    get_common_data(param: string) {
        return this.http.get(
            environment.url + this.URL.commonData + '?param=' + param
        );
    }

    get_about_blocks() {
        return this.http.get(environment.url + this.URL.about_block);
    }

    get_terms_blocks() {
        return this.http.get(environment.url + this.URL.terms_block);
    }

    search_products(text: string) {
        return this.http.get(environment.url + this.URL.product, {
            params: {
                mode: 'bySearch',
                findText: text,
            },
        });
    }

    getCart(cart: any[]) {
        let params = {
            mode: 'for-cart',
            id_products: JSON.stringify(cart.map((pr: any) => pr.id_product)),
        };
        return this.http.get(environment.url + this.URL.product, { params });
    }
}
