import { SettingsService } from 'src/app/_services/settings.service';
import { LanguageService } from './../../../_services/internationality/language.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
// import { QueriesService } from './../../_services/queries.service';
import { HttpClient } from '@angular/common/http';
import { trimValidator } from 'src/app/_utils/formValidationUtils';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {
    passwordConfirmValidatorPartPasswordConfirm,
    passwordConfirmValidatorPartPassword,
} from '../../../_utils/formValidationUtils';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    serverError = '';

    regForm = new FormGroup({
        name: new FormControl('', [
            Validators.required,
            Validators.maxLength(128),
            Validators.minLength(5),
            trimValidator,
        ]),
        phone: new FormControl('', [
            Validators.required,
            Validators.maxLength(9),
            Validators.minLength(9),
            Validators.pattern('^[0-9]*$'),
            trimValidator,
        ]),
        email: new FormControl('', [
            Validators.required,
            Validators.maxLength(128),
            trimValidator,
            Validators.email,
        ]),
        password: new FormControl('', [
            Validators.required,
            Validators.maxLength(16),
            Validators.minLength(6),
        ]),
        acceptTerms: new FormControl(true, [Validators.requiredTrue]),
    });

    constructor(
        private http: HttpClient,
        // private query: QueriesService,
        public langS: LanguageService,
        private auth: AuthService,
        private router: Router,
        public settingsS: SettingsService
    ) {
        this.regForm.addControl(
            'repeatPassword',
            new FormControl('', [
                // @ts-ignore
                passwordConfirmValidatorPartPasswordConfirm.bind(
                    this,
                    // @ts-ignore
                    this.regForm.controls.password
                ),
            ])
        );
        this.regForm.controls.password.setValidators([
            Validators.required,
            Validators.maxLength(128),
            Validators.minLength(6),
            // @ts-ignore
            trimValidator,
            // @ts-ignore
            passwordConfirmValidatorPartPassword.bind(
                this,
                // @ts-ignore
                this.regForm.controls.repeatPassword
            ),
        ]);
    }

    ngOnInit(): void {
        // this.regForm.controls.repeatPassword.setValidators([
        //   Validators.required,
        //   this.checkPasswords.bind(this.regForm)
        // ]);
    }

    checkPasswords(group: FormGroup) {
        // @ts-ignore
        const password = this['controls'].password.value;
        // @ts-ignore
        const repeatPassword = this['controls'].repeatPassword.value;

        return password === repeatPassword ? null : { passwordConfirmError: true };
    }

    confirmRegister() {
        this.regForm.markAllAsTouched();
        this.regForm.updateValueAndValidity();
        if (this.regForm.valid) {
            this.auth
                .register({
                    email: this.regForm.controls.email.value,
                    name: this.regForm.controls.name.value,
                    phone: this.regForm.controls.phone.value,
                    password: this.regForm.controls.password.value,
                })
                .then(
                    (data: any) => {
                        this.regForm.enable();
                        // if (!!data)
                        if (data.status == 'success') {
                            this.settingsS.isShowRegister = false;
                            this.router.navigate(['auth', 'confirm-email']);
                        }
                    },
                    (err) => {
                        if (err.type == 'emailIsBusy')
                            this.serverError = 'emailIsBusyError';
                        this.regForm.enable();
                    }
                );

            this.regForm.disable();
        }
    }
}
