import { Router } from '@angular/router';
import { SettingsService } from 'src/app/_services/settings.service';
import { AuthService } from 'src/app/_services/auth.service';
import { HttpClient } from '@angular/common/http';
import { CartService } from './../../_services/cart.service';
import { QueriesService } from './../../_services/queries.service';
import { LanguageService } from './../../_services/internationality/language.service';
import { Component, OnInit } from '@angular/core';
import { SsrService } from '../../_services/ssr.service';

@Component({
    selector: 'app-cart-page',
    templateUrl: './cart-page.component.html',
    styleUrls: ['./cart-page.component.scss'],
})
export class CartPageComponent implements OnInit {
    constructor(
        public cartS: CartService,
        public settings: SettingsService,
        public langS: LanguageService,
        private queries: QueriesService,
        private httpCl: HttpClient,
        public authS: AuthService,
        private router: Router,
        private ssrS: SsrService
    ) { }

    cart: any[];

    orderForm: any = {
        address: '',
        description: '',
    };

    isShowConfirmOrder = false;

    ngOnInit(): void {
        this.cart = [];
        if (this.ssrS.isBrowser) {
            var cart = this.cartS.getCartFromLocalStorage();
        }
        cart = this.getAuthUserCart(cart);

        // this.authS.onUserDataChanged.subscribe(() => {
        //     console.log('CP')
        //     cart = this.getAuthUserCart(cart);
        // });
    }

    getAuthUserCart(LScart: any) {
        this.httpCl
            .get(this.queries.URL.cart, {
                params: { cart: JSON.stringify(LScart) },
            })
            .subscribe((data: any) => {
                this.cart = data;
                if (!this.authS.isAuth) {
                    if (this.ssrS.isBrowser) {
                        localStorage.setItem(
                            'cart',
                            JSON.stringify(
                                data.map((item: any) => {
                                    return {
                                        id: item.id,
                                        quantity: item.quantity,
                                        id_variant: item.id_variant,
                                        numberInStock: item.variants.find(
                                            (v: any) => v.id === item.id_variant
                                        )?.numberInStock,
                                    };
                                })
                            )
                        );
                    }
                }
            });
    }

    setProductCount(product: any, param: 'minus' | 'plus') {
        if (param == 'minus') {
            product.quantity = product.quantity <= 0 ? 1 : product.quantity - 1 || 1;
        } else {
            product.quantity < product.numberInStock
                ? (product.quantity = product.quantity + 1)
                : '';
        }
        this.cartS.changeCartItemQuantity(product.id, product.quantity);
    }

    deleteFromCart(product: any) {
        this.cartS.deleteFromCart(product.id);
        this.cart = this.cart.filter((pr: any) => pr.id !== product.id);
    }

    getItemPrice(item: any) {
        let price: any = 0;
        let variant = item.variants.find((v: any) => v.id === item.id_variant);
        if (variant) {
            price = variant.price;

            if (item.sale > 0) {
                price = Math.ceil(price - (item.sale / 100) * price);
            }
        }

        return price;
    }

    calcTotal() {
        let total = 0;
        this.cart.forEach((pr: any) => {
            total += this.getItemPrice(pr) * pr.quantity;
        });

        return total;
    }
    getVariantName(item: any) {
        let name = '';
        let variant = item.variants.find((v: any) => v.id === item.id_variant);
        if (variant && item.variants.length > 1) {
            name = ' - ' + variant.title[this.langS.activeLang];
        }

        return name;
    }

    showConfirmOrder() {
        if (this.authS.isAuth && this.authS.user) {
            this.orderForm = {
                name: this.authS.user.name,
                email: this.authS.user.email,
                phone: this.authS.user.phone,
                description: '',
                address: '',
            };
            this.isShowConfirmOrder = true;
        } else {
            this.settings.showLogin();
        }
    }

    confirmOrder() {
        let body: any = {
            products: this.cartS.cart,
            name: this.orderForm.name,
            email: this.orderForm.email,
            phone: this.orderForm.phone,
            address: this.orderForm.address,
            description: this.orderForm.description,
        };
        //console.log(this.cartS.cart, this.cart)
        this.httpCl.post(this.queries.URL.order, body).subscribe((data: any) => {
            this.cartS.clearCart();
            this.cart = [];
            this.isShowConfirmOrder = false;
            this.router.navigate(['/auth', 'order-created']);
        });
    }
}
