import { YsToastService } from './../../_services/ys-toast.service';
import { FavoritesService } from './../../_services/favorites.service';
import { CartService } from './../../_services/cart.service';
import { Meta } from '@angular/platform-browser';
import { LanguageService } from './../../_services/internationality/language.service';
import { SsrService } from './../../_services/ssr.service';
import { HttpClient } from '@angular/common/http';
import { QueriesService } from './../../_services/queries.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageModel } from './../../_models/language.model';
import {
    RealProduct,
    CutedRealProduct,
    Product,
} from './../../_models/productsAndCategories';
import { SettingsService } from './../../_services/settings.service';
import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-product-page',
    templateUrl: './product-page.component.html',
    styleUrls: ['./product-page.component.scss'],
})
export class ProductPageComponent implements OnInit {
    routerParamsChangeSubscription: Subscription;
    productQuantity = 1;
    inStock: Boolean;
    activeVariant: any;

    product: any;
    similarProducts: any[];

    responsiveOptions: any[] = [
        {
            breakpoint: '1024px',
            numVisible: 3,
        },
        {
            breakpoint: '768px',
            numVisible: 3,
        },
        {
            breakpoint: '560px',
            numVisible: 1,
        },
    ];

    // activeInfoTab = 'description';
    activeInfoTab = 'additional';

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private activeRoute: ActivatedRoute,
        public settingsS: SettingsService,
        public cartS: CartService,
        private queriesS: QueriesService,
        private httpClient: HttpClient,
        private ssrS: SsrService,
        public langS: LanguageService,
        private meta: Meta,
        public favoriteS: FavoritesService,
        private ysToast: YsToastService
    ) { }

    ngOnInit(): void {
        this.routerParamsChangeSubscription = this.activeRoute.params.subscribe(
            (params: Params) => {
                //console.log(params)
                this.httpClient
                    .get(this.queriesS.URL.product, {
                        params: { mode: 'oneWithSimilarProducts', id: params.id },
                    })
                    .subscribe(async (resp: any) => {
                        this.inStock = resp.inStock;
                        this.product = resp.product;
                        this.product.sizesImage.unshift(this.product.mainImage);
                        this.settingsS.applySinitizer(this.product.description);
                        this.similarProducts = resp.similarProducts;

                        if (this.product.variants && this.product.variants.length > 0) {
                            this.activeVariant = this.product.variants[0];
                        }
                        //console.log(this.activeVariant)

                        if (this.ssrS.isBrowser) window.scrollTo({ top: 0 });
                    });
            }
        );
    }

    setProductCount(param: 'minus' | 'plus') {
        if (!this.activeVariant) return;

        if (param == 'minus') {
            this.productQuantity =
                this.productQuantity <= 0 ? 1 : this.productQuantity - 1 || 1;
        } else {
            this.productQuantity < this.activeVariant.numberInStock
                ? (this.productQuantity = this.productQuantity + 1)
                : '';
        }
    }

    addToCart() {
        if (!this.activeVariant) return;

        this.cartS.addToCart(
            this.product.id,
            this.activeVariant.id,
            this.productQuantity,
            this.activeVariant.numberInStock
        );

        this.ysToast.showMessage({
            title: this.langS.translate('success'),
            text: this.langS.translate('isAddedToCart'),
            typeToast: 'success',
            duration: 2000,
        });
    }

    selectVariant(variant: any) {
        this.activeVariant = { ...variant };
    }

    // getProductPrice(withSale: boolean = true) {
    //     let price: any = '--';
    //     if (this.activeVariant) {
    //         price = this.activeVariant.price;

    //         if (withSale && this.product.sale > 0) {
    //             price = Math.ceil(price - (this.product.sale / 100) * price);
    //         }
    //     }

    //     return price;
    // }

    getSafeText(description: any) {
        return this.settingsS.sanitizer.bypassSecurityTrustHtml(description);
    }
}
