<div class="container">
  <div *ngIf="type_msg == 'success'" class="success">
    <i class="fa-solid fa-check"></i>
  </div>
  <i
    *ngIf="type_msg == 'error'"
    class="success-icon error-icon flaticon-warning-sign"
  ></i>
  <span *ngIf="type_msg == '404'" class="not-found">404</span>
  <p class="description" [class.not-found-text]="type_msg == '404'">
    {{ msg }}
  </p>

  <button routerLink="/" *ngIf="type_msg !== 'success'" class="btn">
    {{ langS.translate("home") }}
  </button>

  <button (click)="login()" *ngIf="type_msg === 'success'" class="btn">
    {{ langS.translate("login") }}
  </button>
</div>
